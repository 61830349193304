import isEqual from 'lodash/isEqual';

import { RecipientPhoneInfo } from '@/types/types';
import { SPXAPI, ENDPOINTS, BASEURL } from '@/api';
import { captureErrorForSentry } from '@/components/utils';
import { PHONE_REGEXP } from '@/regExp';

import { EmailWithPhone } from './interfaces';

export const trySavePhoneNumber = async (
    API: SPXAPI,
    { email, ...phone }: EmailWithPhone,
    cognitoToken: string,
): Promise<boolean> => {
    try {
        await API.post(
            BASEURL.backend(),
            ENDPOINTS.setPhoneNumber(email),
            { body: phone, headers: { Authorization: cognitoToken } },
        );
        return true;
    } catch (error) {
        captureErrorForSentry(error, 'SharedUsersStore.trySavePhoneNumber');
        return false;
    }
};

export const mapEmailsToPhones = (emails: string[], phonesMap: Map<string, RecipientPhoneInfo>): EmailWithPhone[] => (
    emails.reduce<EmailWithPhone[]>((acc, email) => {
        const phoneInfo = phonesMap.get(email);
        if (phoneInfo?.phonesVector) {
            const { initSnapshot, currentPhone } = phoneInfo.phonesVector;
            if (!isEqual(initSnapshot, currentPhone)) {
                acc.push({
                    email,
                    phone: currentPhone?.phone,
                    prefix: currentPhone?.prefix,
                });
            }
        }
        return acc;
    }, [])
);

export const checkPhonesAreValid = (phones: string[]): boolean => (
    phones.every((phone) => PHONE_REGEXP.test(phone))
);
