import { FC, useEffect, useState } from 'react';

import { observer } from 'mobx-react';
import { ConfigProvider } from 'antd';
import { useTranslation } from 'react-i18next';

import { captureException } from '@sentry/react';
import { useStores } from '@/components/hooks';

import { PageLanguageContextProvider } from '../PageLanguageContextProvider';
import { ShareDrawer } from './ShareDrawer';
import { PublicFooter } from '@/components/Common/PublicComponents';
import Spinner from '@/components/Common/Spin';
import Header from '../Common/Header';

import { MAP_ORIGIN_TO_LOCAL_STORAGE_KEY } from '@/consts';
import {
    getFilesIdsQueryString,
    prepareFilesForMobX,
} from '@/components/ExternalStorage/helpers';
import {
    captureUnexpectedNetworkError,
    getCognitoUserToken,
} from '@/components/utils';
import NoFilesError from '@/components/ExternalStorage/NoFilesError';
import { ExternalStorageFileResponse } from '@/components/ExternalStorage/interfaces';
import { BASEURL, ENDPOINTS } from '@/api';

import styles from './ShareDrawerPage.module.scss';
import { API as AmplifyAPI } from '@aws-amplify/api/lib-esm/API';
import ConnectGDriveAccountBanner from '@/components/MiniApps/ShareDrawerPage/ConnectGdriveAccountBanner';

// For now we only using googledrive as storage provider
const ORIGIN = 'googledrive';

const ShareDrawerPage: FC = () => {
    const [clientId, setClientId] = useState<string>('');
    const [connectionBanner, setConnectionBanner] = useState<boolean>(false);

    const { i18n } = useTranslation();
    const {
        uploadFilesStore: { setIsLoading, setFilesList },
        policyStore,
        sharedUsersStore: { fetchEmailsList, finishRecipientsFlow },
        policyStore: { fetchPolicyList, unmount },
        authSettingsStore: { API },
    } = useStores();

    const loadClientInfo = async () => {
        try {
            const jwtToken = await getCognitoUserToken();
            if (jwtToken) {
                const result = await AmplifyAPI.get(BASEURL.backend(), ENDPOINTS.isGoogleRegister(), { headers: { Authorization: jwtToken } });
                const cognitoClientId = result.client_id;
                setClientId(cognitoClientId);
            }
        } catch (error) {
            console.log('>>> Unable To Load Client Id');
        }
    }

    const loadFiles = async (filesIdsQueryString: string): Promise<ExternalStorageFileResponse[] | never> => {
        try {
            const { items }: { items: ExternalStorageFileResponse[] } = await API.get(
                BASEURL.backend(),
                ENDPOINTS.getSharedFiles(ORIGIN, filesIdsQueryString),
                {},
            );

            if (!items.length) {
                await loadClientInfo();
                setConnectionBanner(true);
                console.warn('files list is empty');
                throw new NoFilesError('Files list is empty');
            }
            return items;
        } catch (error) {
            const isBannerNeeded = (error as Error).message === 'Request failed with status code 403';
            if (isBannerNeeded) {
                await loadClientInfo();
                setConnectionBanner(isBannerNeeded);
            }
        }
    };

    const loadData = async (
        filesIdsQueryString: string, isPoliciesEditable: boolean,
    ): Promise<ExternalStorageFileResponse[]> => {
        const requestPromises: Promise<void | ExternalStorageFileResponse[]>[] = [loadFiles(filesIdsQueryString)];
        requestPromises.push(fetchEmailsList());

        if (isPoliciesEditable) {
            requestPromises.push(fetchPolicyList());
        }
        const [files] = await Promise.all(requestPromises);
        return files as ExternalStorageFileResponse[];
    };

    const tryLoadData = async (): Promise<void> => {
        try {
            const filesIdsQueryString = getFilesIdsQueryString(ORIGIN);
            const files = await loadData(filesIdsQueryString, true);
            const { filesForUploadStore } = prepareFilesForMobX(
                files, policyStore.policyList, ORIGIN, true,
            );
            setFilesList(filesForUploadStore);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            const isNoFilesError = error instanceof NoFilesError;
            if (isNoFilesError) {
                captureException(error);
            } else {
                captureUnexpectedNetworkError(error, 'ExternalStorage.tryLoadData');
            }
        }
    };

    useEffect(() => {
        const localStorageFiles = sessionStorage.getItem(MAP_ORIGIN_TO_LOCAL_STORAGE_KEY[ORIGIN]);
        window.history.pushState({}, '', `?state=${localStorageFiles}`);
        setIsLoading(true);
        tryLoadData();

        return () => {
            unmount();
            finishRecipientsFlow();
        };
    }, []);

    return (
        <PageLanguageContextProvider>
            <ConfigProvider direction={i18n.dir()}>
                <Spinner spinning={false}>
                    <div className={styles.container}>
                        <Header />
                        {connectionBanner
                            ? <ConnectGDriveAccountBanner
                                clientId={clientId}
                                setConnectionBanner={setConnectionBanner}
                                tryLoadData={tryLoadData}
                            />
                            : <ShareDrawer />
                        }
                        <PublicFooter showSkeleton={false} />
                    </div>
                </Spinner>
            </ConfigProvider>
        </PageLanguageContextProvider>

    );
};

export default observer(ShareDrawerPage);
