import React, { FC, memo } from 'react';

import ConnectButton from '../ConnectButton';
import useGoogleIdentityService, { GoogleIdentityService } from './useGoogleIdentityService';
import { ConnectExternalStorageProps } from '../interfaces';

const GoogleOauthButton: FC<ConnectExternalStorageProps> = ({
    responseFail, responseSuccess, onClick, isLoading = false, clientId,
}) => {
    const {
        isExternalScriptLoaded, oauthClickHandler,
    }: GoogleIdentityService = useGoogleIdentityService({ clientId, responseSuccess, responseFail });

    return (
        <ConnectButton
            oauthClickHandler={oauthClickHandler}
            onClick={onClick}
            isLoading={isLoading}
            isDisabled={!isExternalScriptLoaded}
            storageProvider="googledrive"
        />
    );
};

export default memo(GoogleOauthButton);
