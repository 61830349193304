import React, { FC, memo } from 'react';

import { Button } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import classNames from 'classnames';

import {
    BoxOauthButton,
    GoogleOauthButton,
    ResponseSuccess,
    ResponseFail,
} from '../../Common/ConnectExternalStorageButton';
import { useStores } from '../../hooks';
import ability from '@/config/ability';
import { StorageConnectionState } from '@/types/types';
import styles from './UserSettingsModal.module.scss';

interface UserSettingsModalProps {
    googleConnection: StorageConnectionState;
    boxConnection: StorageConnectionState;
    onVisibleChange: (newValue: boolean) => void;
    responseGoogleSuccess: ResponseSuccess;
    responseGoogleFail: ResponseFail;
    responseBoxSuccess: ResponseSuccess;
    responseBoxFail: ResponseFail;
}

const UserSettingsModal: FC<UserSettingsModalProps> = ({
    googleConnection,
    boxConnection,
    onVisibleChange,
    responseGoogleSuccess,
    responseGoogleFail,
    responseBoxSuccess,
    responseBoxFail,
}) => {
    const { userStore } = useStores();

    const logout = async (): Promise<void> => {
        onVisibleChange(false);
        await userStore.cognitoSignOut();
        ability.update([]);
    };

    const showGoogleLogin = googleConnection.isDisconnected && googleConnection.clientId;
    const showBoxLogin = boxConnection.isDisconnected && boxConnection.clientId;

    return (
        <div className={styles['user-settings-modal']}>
            {showGoogleLogin && (
                <GoogleOauthButton
                    responseSuccess={responseGoogleSuccess}
                    responseFail={responseGoogleFail}
                    clientId={googleConnection.clientId}
                />
            )}
            {showBoxLogin && (
                <BoxOauthButton
                    responseFail={responseBoxFail}
                    responseSuccess={responseBoxSuccess}
                    clientId={boxConnection.clientId}
                />
            )}
            <Button
                danger
                type="text"
                data-testid="logout"
                className={classNames(styles['setting-item'], styles.logout)}
                onClick={logout}
                icon={<LogoutOutlined />}
            >
                Logout
            </Button>
        </div>
    );
};

export default memo(UserSettingsModal);
