import React from 'react';
import ReactDOM from 'react-dom/client';

import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'mobx-react';
import Helmet from 'react-helmet';
import { ConfigProvider } from 'antd';

import EnvironmentDetector from './components/EnvironmentDetector';
import ErrorsBoundary from './components/ErrorsBoundary';
import Root from './components/Root';
import HotjarWrapper from './components/HotjarWrapper';
import { AppStore, AllStores } from './stores';
import configureAmplify from './config/amplify';
import { BASE_THEME_CONFIG } from '@/theme';
import './index.scss';
import 'antd/dist/reset.css';

configureAmplify(true);

const initStores = (): AllStores => {
    const appStore = new AppStore();
    return appStore.stores;
};

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <EnvironmentDetector>
        <BrowserRouter>
            <>
                <Helmet>
                    <meta
                        name="description"
                        content="Manage and control the entire organizational data from a single place,
                            no matter where it’s stored."
                    />
                </Helmet>
                <Provider {...initStores()}>
                    <ErrorsBoundary>
                        <HotjarWrapper>
                            <ConfigProvider theme={BASE_THEME_CONFIG}>
                                <Root />
                            </ConfigProvider>
                        </HotjarWrapper>
                    </ErrorsBoundary>
                </Provider>
            </>
        </BrowserRouter>
    </EnvironmentDetector>,
);
