import {
    action,
    computed,
    makeObservable,
    observable,
} from 'mobx';

import {
    CancellableAPI,
    getPhoneByEmailCancellable,
} from '@/api';
import {
    PhoneIdentity, PhonesVector, RecipientPhoneInfo, SharedUser,
} from '@/types/types';
import { captureErrorForSentry, checkIs404, getCognitoUserToken } from '@/components/utils';

import AuthSettingsStore from '../AuthSettingsStore';
import UsersListStore from '../UsersListStore';
import type { EmailWithPhone } from './interfaces';
import { checkPhonesAreValid, mapEmailsToPhones, trySavePhoneNumber } from './helpers';
import { INIT_PHONE_INFO } from './constants';

class UsersPhonesStore {
    constructor(
        authSettingsStore: AuthSettingsStore,
        usersListStore: UsersListStore,
    ) {
        this.authSettingsStore = authSettingsStore;
        this.usersListStore = usersListStore;
        this.cancellableAPI = new CancellableAPI(authSettingsStore, { failSilently: false });
        makeObservable(this);
    }

    @observable usersPhonesMap: Map<string, RecipientPhoneInfo> = new Map<string, RecipientPhoneInfo>();

    @observable phoneNumbersGroupId = null;

    @observable loadingGroupMembersModal = false;

    @observable isPhoneSaved: boolean | null = null;

    private readonly authSettingsStore: AuthSettingsStore;

    private readonly cancellableAPI: CancellableAPI;

    private readonly usersListStore: UsersListStore;

    @computed
    get hasMissingNumber(): boolean {
        // TODO: add check for loading groups members
        return [...this.usersPhonesMap.values()].some(({
            phonesVector,
            isSearching,
        }) => (
            !phonesVector?.currentPhone?.phone
            && !isSearching
        ));
    }

    @computed
    get hasSearchingNumber(): boolean {
        return [...this.usersPhonesMap.values()].some(({ isSearching }) => isSearching);
    }

    @action
    setRecipientPhoneInfo = (email: string, phoneInfo: RecipientPhoneInfo): void => {
        this.usersPhonesMap.set(email, phoneInfo);
    };

    @action
    setPhoneNumbersGroupId = (value: string): void => {
        this.phoneNumbersGroupId = value;
    };

    @action
    setLoadingGroupMembersModal = (value: boolean): void => {
        this.loadingGroupMembersModal = value;
    };

    @action
    setIsPhoneSaved = (value: boolean): void => {
        this.isPhoneSaved = value;
    };

    fetchSharedUsersPhones = async (users: SharedUser[]): Promise<void> => {
        // TODO: fetch group members
        const emailList: string[] = users.reduce<string[]>((acc, current) => {
            if (current.recipientType !== 'group') {
                acc.push(current.email);
            }
            return acc;
        }, []);
        await this.fillPhones(emailList);
    };

    fillPhones = async (emails: string | string[]): Promise<void> => {
        const emailsList: string[] = typeof emails === 'string' ? [emails] : emails;
        await Promise.all(emailsList.map(async (email) => {
            if (!this.usersPhonesMap.has(email)) {
                this.setRecipientPhoneInfo(email, { ...INIT_PHONE_INFO });
                const phoneInfo = await this.searchPhone(email);
                if (this.usersPhonesMap.has(email)) {
                    this.setRecipientPhoneInfo(email, phoneInfo);
                }
            }
        }));
    };

    fetchGroupMembersPhones = async (targetGroupId: string): Promise<void> => {
        this.setLoadingGroupMembersModal(true);
        if (this.usersListStore.userGroupsList.some(({ groupId }) => groupId === targetGroupId)) {
            await this.usersListStore.fetchUsersCustomGroup(targetGroupId);
            const group = this.usersListStore.userGroupsList.find(({ groupId }) => groupId === targetGroupId);
            const membersEmails = (group?.members || []).map<string>(({ email }) => email);
            await this.fillPhones(membersEmails);
        }
        this.setLoadingGroupMembersModal(false);
    };

    tryBulkSavePhones = async (emails: string[]): Promise<boolean> => {
        const { authSettingsStore: { API } } = this;
        const phonesToSave = mapEmailsToPhones(emails, this.usersPhonesMap);
        if (!checkPhonesAreValid(phonesToSave.map<string>(({ phone }) => phone))) {
            return false;
        }
        const token = await getCognitoUserToken();
        const results = await Promise.all(phonesToSave.map(
            (item) => trySavePhoneNumber(API, item, token),
        ));
        return results.every(Boolean);
    };

    getUserPhone = (email: string): RecipientPhoneInfo => {
        const phoneInfo = this.usersPhonesMap.get(email) || INIT_PHONE_INFO;
        return phoneInfo;
    };

    changeRecipientPhone = (email: string, phoneInfo: PhoneIdentity): void => {
        const phoneData = this.usersPhonesMap.get(email);
        if (phoneData) {
            const newPhoneData: PhonesVector = {
                ...phoneData.phonesVector,
                currentPhone: phoneInfo,
                initSnapshot: phoneInfo,
            };
            this.setRecipientPhoneInfo(email, {
                ...phoneData,
                phonesVector: newPhoneData,
            });
        }
    };

    searchPhone = async (emailVal: string): Promise<RecipientPhoneInfo> => {
        try {
            const { phone, prefix } = await getPhoneByEmailCancellable(emailVal, this.cancellableAPI);
            return {
                isSearching: false,
                phonesVector: {
                    initSnapshot: { phone, prefix },
                    currentPhone: { phone, prefix },
                },
            };
        } catch (error) {
            if (!checkIs404(error)) {
                captureErrorForSentry(error, 'SharingBlock.searchPhone');
            }
            return {
                isSearching: false,
                phonesVector: {
                    initSnapshot: null,
                    currentPhone: null,
                },
            };
        }
    };

    fetchUserPhone = async (email: string): Promise<void> => {
        if (!this.usersPhonesMap.has(email)) {
            this.setRecipientPhoneInfo(email, { ...INIT_PHONE_INFO });
            const phoneInfo = await this.searchPhone(email);
            if (this.usersPhonesMap.has(email)) {
                this.setRecipientPhoneInfo(email, phoneInfo);
            }
        }
    };

    saveRecipientPhone = (email: string, phone: PhoneIdentity): void => {
        const phoneInfo = this.usersPhonesMap.get(email);
        if (phoneInfo) {
            this.setRecipientPhoneInfo(email, {
                ...phoneInfo,
                phonesVector: { ...phoneInfo.phonesVector, currentPhone: phone },
            });
        }
    };

    tryBulkSaveGroupPhoneNumbers = async (): Promise<boolean> => {
        const targetEmails = (this.usersListStore.userGroupsList
            .find(({ groupId }) => this.phoneNumbersGroupId === groupId)?.members || [])
            .map(({ email }) => email);
        return this.tryBulkSavePhones(targetEmails);
    };

    changePhoneNumber = async (payload: EmailWithPhone): Promise<boolean> => {
        const { authSettingsStore: { API } } = this;
        const { email, ...phone } = payload;
        const token = await getCognitoUserToken();
        const result = await trySavePhoneNumber(API, payload, token);
        if (result) {
            this.changeRecipientPhone(email, phone);
        }
        return result;
    };

    clearPhones(): void {
        this.cancelAll();
        this.usersPhonesMap.clear();
    }

    deletePhone(email: string): void {
        this.usersPhonesMap.delete(email);
        this.cancel(email);
    }

    createNew = (): UsersPhonesStore => (
        new UsersPhonesStore(this.authSettingsStore, this.usersListStore)
    );

    private cancel(requestKey: string): void {
        this.cancellableAPI.cancelRequest(requestKey);
    }

    private cancelAll(): void {
        this.cancellableAPI.cancelAll();
    }
}

export default UsersPhonesStore;
