import React, { SyntheticEvent, Component } from 'react';

import { Navigate } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Auth } from 'aws-amplify';
import { ICredentials } from 'aws-amplify/lib/Common/types/types';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ISignUpResult } from 'amazon-cognito-identity-js';
import classNames from 'classnames';

import { SpInput } from '@/components/Common/Sp';
import i18n from '@/content';
import PasswordValidation from '../../Common/PasswordValidation';
import TabTitle from '../../Common/TabTitle';
import AuthSubmitButton from '../AuthSubmitButton';
import AuthPageTitle from '../AuthPageTitle';
import { AuthToggleLink } from '../AuthLink';
import AltAuthSeparator from '../AltAuthSeparator';
import FederatedComp from '../FederatedButton';
import { AuthStates } from '@/types/types';
import config, { SSO_ORDER, SSOTypes } from '../../../config/env';
import { AuthContainerProps } from '../AuthContainer/AuthContainer';
import { toTitleCase } from '../../utils';
import { validateEmail } from '../../validators';
import { getSingInQueryParam, extractSavedFederatedProvider } from '../helpers';
import { IS_INSIDE_IFRAME } from '@/config/browserEnv';
import styles from './SignUp.module.scss';

interface SignUpState {
    email: { value: string, isValid: boolean };
    password: { value: string, isValid: boolean };
    signIn: boolean;
    SSOList: SSOTypes[];
}

@inject('appStore')
@observer
class SignUp extends Component<AuthContainerProps, SignUpState> {
    private readonly nameSpace = 'signUp';

    constructor(props: AuthContainerProps) {
        super(props);
        this.state = {
            email: { value: '', isValid: false },
            password: { value: '', isValid: false },
            signIn: false,
            SSOList: [...config.SSO].sort((current, prev) => SSO_ORDER[current] - SSO_ORDER[prev]),
        };
    }

    async componentDidMount(): Promise<void> {
        const {
            appStore: {
                setLoadingStatus,
                userStore: { hasDoubleAuthError },
            },
        } = this.props;
        const signInQuery = getSingInQueryParam();

        if (!IS_INSIDE_IFRAME && (hasDoubleAuthError || signInQuery)) {
            setLoadingStatus(true);
            const federatedProvider = hasDoubleAuthError ? extractSavedFederatedProvider() : signInQuery;
            if (federatedProvider) {
                await this.doFederatedSignIn(federatedProvider);
            }
        }
    }

    doFederatedSignIn = async (provider: string): Promise<void> => {
        const { signInCalls } = this.props;
        const callBackFunc = (): Promise<ICredentials> => (
            Auth.federatedSignIn({ customProvider: toTitleCase(provider) })
        );
        await signInCalls(callBackFunc, 'signIn', { authState: AuthStates.authenticated, isFederated: true });
    }

    updatePassword = (value: string, isValid: boolean): void => this.setState({ password: { value, isValid } })

    updateEmail = (value: string): void => {
        this.setState({ email: { value, isValid: validateEmail(value) } });
    }

    validateForm = (): boolean => {
        const { password, email } = this.state;
        return email.isValid && password.isValid;
    };

    doRegister = async (event: SyntheticEvent): Promise<void> => {
        event.preventDefault();
        const { password, email } = this.state;

        const username = email.value.toLowerCase();
        const passwordVal = password.value;
        const { signInCalls } = this.props;

        const callBackFunc = (): Promise<ISignUpResult> => (
            Auth.signUp({ username, password: passwordVal })
        );
        const isSignedIn = await signInCalls(callBackFunc, this.nameSpace, { username, authState: AuthStates.unauthenticated, password: passwordVal });
        if (isSignedIn) {
            this.setState({
                signIn: true,
            });
        }
    }

    render(): JSX.Element {
        const {
            email,
            password,
            signIn,
            SSOList,
        } = this.state;
        const { nameSpace } = this;

        if (signIn) {
            return <Navigate to="signIn" />;
        }

        const cognitoExist = SSOList.includes(SSOTypes.cognito);
        const isFormValid = this.validateForm();
        const wrapperClasses = classNames(styles['signup-wrapper'], { [styles['federated-only']]: !cognitoExist });

        return (
            <>
                <TabTitle title={i18n.t(`${nameSpace}.tabTitle`)} />

                <div className={wrapperClasses}>

                    <AuthPageTitle titleKey={`${nameSpace}.pageTitle`} />

                    {SSOList.map((type) => type !== SSOTypes.cognito && (
                        <FederatedComp key={type} doFederatedSignIn={this.doFederatedSignIn} type={type} />
                    ))}

                    {cognitoExist
                        && (
                            <>
                                {SSOList.length > 1
                                && <AltAuthSeparator message={i18n.t(`${nameSpace}.orSignUp`)} />}

                                <form onSubmit={this.doRegister}>
                                    <SpInput
                                        type="text"
                                        value={email.value}
                                        placeholder={i18n.t(`${nameSpace}.placeholder`)}
                                        onChange={this.updateEmail}
                                    />
                                    <PasswordValidation password={password.value} updateInput={this.updatePassword} />
                                    <AuthSubmitButton
                                        disabled={!isFormValid}
                                        onClick={this.doRegister}
                                        titleKey={`${nameSpace}.signUp`}
                                    />
                                </form>
                            </>
                        )}

                    <AuthToggleLink pageType="signUp" />
                </div>

            </>
        );
    }
}

export default SignUp;
