import React from 'react';
import { Select, Upload, Steps, Button, Typography, Spin, Result, message } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { ENDPOINTS, BASEURL } from '@/api';
import { ModalContentProps } from '../Modal';
import axios from 'axios';
import { inject, observer } from 'mobx-react';

import AuthSettingsStore from '../../../stores/AuthSettingsStore';
import UserStore from '../../../stores/UserStore';
import SharedUsersStore from '../../../stores/SharedUsersStore';
import './index.scss';
import { captureErrorForSentry } from '../../utils';
import { withTranslation, WithTranslation as WithTranslationProps } from 'react-i18next';
import { RecipientPageStore, SharedFilesStore } from '@/stores';

const { Title } = Typography;
const { Dragger } = Upload;
const { Step } = Steps;
const { Option } = Select;
const nameSpace = 'fileModule.recipientBlock';

interface SendFilesProps extends ModalContentProps, WithTranslationProps {
    closeModal?: (success: boolean) => void;
    authSettingsStore?: AuthSettingsStore;
    userStore?: UserStore;
    sharedUsersStore?: SharedUsersStore;
    recipientPageStore?: RecipientPageStore;
    allowMultipleSharing?: boolean;
    sourceEmail?: string;
}

interface SendFilesState {
    current: number;
    progress: number;
    fileData: any;
    fileList: any[];
    shareWith: string;
    uploading: boolean;
}

@inject('authSettingsStore', 'userStore', 'sharedUsersStore', 'recipientPageStore')
@observer
class SendFiles extends React.PureComponent<SendFilesProps, SendFilesState> {
    MB_LIMIT: number;
    BYTE_LIMIT: number;

    constructor(props) {
        super(props);
        this.MB_LIMIT = 100;
        this.BYTE_LIMIT = this.MB_LIMIT * 1000000;
        this.state = {
            fileList: [],
            current: 0,
            progress: 0,
            uploading: false,
            shareWith: '',
            fileData: { url: '', file_id: '' },
        };
    }

    onFileLoaded = async (event, getUrlPromise, onProgress, onSuccess, file) => {
        const config = {
            onUploadProgress: (progressEvent) => {
                var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                onProgress({ percent: percentCompleted }, event.target);
                this.setState({ progress: percentCompleted });
            },
        };
        const { data: { url } } = await getUrlPromise;
        const fileContent = event.target.result;
        var int8View = new Int8Array(fileContent);
        try {
            await axios.put(url, int8View, config);
            this.setState({ uploading: false }, () => {
                onSuccess('good good', file);
            });
        } catch (e) {
            // error handling
            this.checkFilenameError(e);
        }

    };

    checkFilenameError = (e) => {
        const { t } = this.props;
        /* Handling filename starting character error which comes from BackEnd.
        Should not be ever called, because this error should be caught in FrontEnd, in checkFilename function
        before trying to pass the file for uploading to BE.
         */
        const checkerror = e;
        if (checkerror.response.data.message === 'Failed loading request params. Details: Filename starts with inappropriate symbol') {
            message.error(t(`${nameSpace}.errors.inappropriateSymbol`));
        } else {
            message.error(t(`${nameSpace}.errors.couldNotUploadFile`));
        }
    };

    checkFilename = (file) => {
        const { t } = this.props;
        //* Checking filename starting char to avoid possible CSV injection' */
        const badStartingChars = '-+=@';
        const startingChar = file.name[0];
        if (badStartingChars.includes(startingChar)) {
            message.info(t(`${nameSpace}.errors.invalidCharacter`));
            return false;
        }

        return true;
    };

    checkSize = (file) => {
        const { t } = this.props;
        const fileSize = file.size;
        if (fileSize > this.BYTE_LIMIT) {
            message.info(t(`${nameSpace}.errors.bigFile1Gb`));
            return false;
        }

        return true;
    };

    runPreChecks = (file) => {
        return this.checkFilename(file);
    };

    uploadFile = async (fileData) => {
        const { file, onProgress, onSuccess } = fileData;
        const { API } = this.props.authSettingsStore;
        const { sharedFilesStore: { linkId } } = this.props.recipientPageStore;

        const { setErrorStatus } = this.props;
        try {
            this.setState({ uploading: true });
            const getUrlPromiseData = { filename: file.name, regions: ['eu-central'], linkId };

            const getUrlPromise = await API.post(BASEURL.backend(), ENDPOINTS.uploadFile(), {
                response: true,
                headers: {},
                body: getUrlPromiseData,
            });

            const fileReader = new FileReader();
            fileReader.onload = event => this.onFileLoaded(event, getUrlPromise, onProgress, onSuccess, file);
            fileReader.readAsArrayBuffer(file);
            this.setState({
                fileData: getUrlPromise.data,
            });

        } catch (e) {
            captureErrorForSentry(e, 'SendFiles.uploadFile');
            setErrorStatus(true, 'Could not upload file');
        }
    };

    closeModal = (): void => {
        const { closeModal: closeModalProp, resetParentModalState } = this.props;
        this.setState({ current: 0, fileList: [] });
        closeModalProp(true);
        resetParentModalState && resetParentModalState();
    };

    getContent = () => {
        const {
            current,
            fileList,
        } = this.state;

        const {
            sharedUsersStore: { usersSharedOwnersList },
            t,
            allowMultipleSharing,
            sourceEmail,
        } = this.props;
        const showUploadList = {
            showDownloadIcon: false,
            showRemoveIcon: false,
        };
        switch (current) {
            case 0:
                return (
                    <div className="dragger-wrapper">
                        <Dragger
                            disabled={this.state.uploading}
                            onChange={this.onChangeFile}
                            showUploadList={showUploadList}
                            beforeUpload={this.runPreChecks}
                            method="put"
                            fileList={fileList}
                            customRequest={this.uploadFile}
                        >
                            <Spin spinning={this.state.uploading}>
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">{t(`${nameSpace}.clickOrDrag`)}</p>
                            </Spin>
                        </Dragger>
                    </div>
                );

            case 1:
                return (
                    <div className="share-with-wrap" id="area">

                        <Title level={4}>{t(`${nameSpace}.shareWith`)}</Title>
                        {!sourceEmail ? <Select
                                showSearch
                                style={{ width: 200 }}
                                onChange={this.setShareWith}
                                placeholder={t(`${nameSpace}.selectPerson`)}
                                optionFilterProp="children"
                                filterOption={(input, option) => {
                                    const optionVal = option.props.value.toString();
                                    return optionVal.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                }
                                }
                            >
                                {usersSharedOwnersList.map(({ owner_email, owner }) => {
                                    return <Option key={owner_email}
                                                   value={owner_email}>{owner ? owner : owner_email}</Option>;
                                })
                                }
                            </Select>
                            : <span>{sourceEmail}</span>}
                        <div className="button-wrapper">
                            <Button
                                loading={this.state.uploading}
                                type="primary"
                                onClick={this.shareWith}
                            >
                                {t('general.specterxCommon.share')}
                            </Button>
                        </div>


                    </div>
                );
            case 2:
                return (
                    <div className="steps-action">
                        <Result
                            status="success"
                            title={t(`${nameSpace}.sharingIsCaring`)}
                            className="share-success" />
                        <Button type="primary" onClick={() => this.closeModal()}>
                            {t(`${nameSpace}.buttons.done`)}
                        </Button>
                    </div>

                );
            default:
                return null;
        }
    };

    setShareWith = (value) => {
        this.setState({
            shareWith: value,
        });
    };

    shareWith = async () => {
        const { shareWith, fileData } = this.state;
        const { setErrorStatus, authSettingsStore, sourceEmail } = this.props;
        const { API } = authSettingsStore;

        const fileId = fileData.file_id;
        this.setState({
            uploading: true,
        });

        try {
            await API.put(BASEURL.backend(), ENDPOINTS.shareFile(fileId), {
                body: {
                    email: shareWith || sourceEmail,
                },
            });

            const current = this.state.current + 1;
            this.setState({ current, uploading: false });
        } catch (e) {
            captureErrorForSentry(e, 'SendFiles.shareWith');
            setErrorStatus(true, 'Could not upload file');
        }

    };

    onChangeFile = ({ file, fileList }) => {
        const { setErrorStatus, t } = this.props;

        if (file.status == 'error') {
            setErrorStatus(true, 'Could not upload file');
        }

        if (file.status == 'done') {
            const current = this.state.current + 1;
            this.setState({ current, uploading: false });
        }

        if (file.size > this.BYTE_LIMIT) {
            message.error(t(`${nameSpace}.errors.bigFile`, { sizeLimit: '1GB' }));
        } else {
            this.setState({ fileList: [...fileList] });
        }
    };

    render() {
        const { current } = this.state;
        const { t } = this.props;

        return (
            <div className="share-with-external">
                <Steps current={current} labelPlacement="vertical">
                    <Step title={t(`${nameSpace}.steps.uploadFile`)} />
                    <Step title={t(`${nameSpace}.steps.shareFiles`)} />
                    <Step title={t(`${nameSpace}.steps.confirm`)} />
                </Steps>
                {this.getContent()}
            </div>
        );
    }
}

export default withTranslation()(SendFiles);
