import { LANGUAGES } from '@/content/languages';
import { APP_ROUTES_DEFAULT, AppRoutes, RoutesListType } from './appRoutes';

interface WSO {
    CLIENT_SECRET: string;
    CLIENT_ID: string;
    DOMAIN: string;
    REDIRECT_URL: string;
}

export interface API_PROXY {
    CHECK_APP_INFO_BEFORE_USING: boolean;
    API_URL: string;
    APP_URL: string;
    DISABLE_MFA_BEHIND_PROXY: boolean;
    DISABLE_OTP_BEHIND_PROXY: boolean;
}

export enum SSOTypes {
    cognito = 'cognito',
    google = 'google',
    microsoft = 'microsoft',
    okta = 'okta',
}

interface Cognito {
    USER_POOL_ID: string;
    APP_CLIENT_ID: string;
    IDENTITY_POOL_ID: string;
    DOMAIN: string;
}

export interface ViewerConfig {
    CANCEL_MOBILE_VIEWER_FILE_EXTENSION_CHECK?: boolean;
    HIDE_DOWNLOAD_BUTTON?: boolean;
}

export interface EnvConfig {
    COMPANY_LOGO: string;
    HEADER_COMPANY_LOGO: string;
    HEADER_SMALL_COMPANY_LOGO: string;
    API_URL: string;
    APP_URL: string;
    cognito: Cognito;
    WSO?: WSO;
    API_PROXY?: API_PROXY;
    SSO: SSOTypes[];
    DEFAULT_LANGUAGE?: LANGUAGES;
    HAS_FULL_OTP_AUTH?: boolean;
    SHOW_FEDERATED_POPUP?: boolean;
    DISABLE_GOOGLE_REGISTER_CHECK?: boolean;
    HIDE_SHARED_FILE_POLICIES?: boolean;
    HIDE_UPLOAD_BUTTON?: boolean;
    DISALLOW_SHARE_WHILE_FILE_UPLOADING?: boolean;
    DISABLE_CREATE_FOLDER?: boolean;
    viewer?: ViewerConfig;
    HIDE_FAQ_BUTTON?: boolean;
    ROUTES?: RoutesListType;
    ENABLE_WORKSPACE?: boolean;
    ENABLE_IP_RESTRICTION?: boolean;
    SHOW_UPLOAD_DISCLAIMER?: boolean;
    DISABLE_SELF_PHONE_FILLING?: boolean;
    ENABLE_OPSWAT_FILE_SCANNING?: boolean;
    UPLOAD_FILES_COUNT_LIMIT?: number;
    SHOW_SHARE_BACK_BUTTON?: boolean;
    ENABLE_SHARE_MESSAGE?: boolean;
    DEFAULT_PHONE_CHECK?: boolean;
    IS_MULTITENANCY?: boolean;
}

const local: EnvConfig = {
    COMPANY_LOGO: '/assets/company_logo/Specter-X-Logo-Light-M.png',
    HEADER_COMPANY_LOGO: '/assets/header_company_logo/Specter-X-Logo-Dark.svg',
    HEADER_SMALL_COMPANY_LOGO: '/assets/header_company_logo/small_logos/specterx-icon.png',
    API_URL: 'https://localhost:5000',
    APP_URL: 'https://localhost:3000',
    cognito: {
        USER_POOL_ID: 'eu-central-1_4f3TZWBv1',
        APP_CLIENT_ID: '65fa7l3nh48aecks3c0vq0r6k5',
        IDENTITY_POOL_ID: 'eu-central-1:fba0a33d-513f-4983-81d7-27e570fa33d0',
        DOMAIN: 'dev-users.auth.eu-central-1.amazoncognito.com',
    },
    SSO: [SSOTypes.cognito, SSOTypes.microsoft, SSOTypes.google],
    DISABLE_GOOGLE_REGISTER_CHECK: false,
};

const demo: EnvConfig = {
    COMPANY_LOGO: '/assets/company_logo/Specter-X-Logo-Light-M.png',
    HEADER_COMPANY_LOGO: '/assets/header_company_logo/varonis-logo-dark.png',
    HEADER_SMALL_COMPANY_LOGO: '/assets/header_company_logo/small_logos/Varonis.png',
    API_URL: 'https://demo-api.specterx.com',
    APP_URL: 'https://demo-app.specterx.com',
    cognito: {
        USER_POOL_ID: 'eu-central-1_O6E9zTW8c',
        APP_CLIENT_ID: '3p6jb5gjlv30ourqlcggjnguo7',
        IDENTITY_POOL_ID: 'eu-central-1:1006bc08-5c40-41ec-9b86-54c4c9c0ecbe',
        DOMAIN: 'demo-users.auth.eu-central-1.amazoncognito.com',
    },
    SSO: [SSOTypes.cognito, SSOTypes.google],
    DISABLE_GOOGLE_REGISTER_CHECK: false,
};

const demo2: EnvConfig = {
    COMPANY_LOGO: '/assets/company_logo/Specter-X-Logo-Light-M.png',
    HEADER_COMPANY_LOGO: '/assets/header_company_logo/Specter-X-Logo-Dark.svg',
    HEADER_SMALL_COMPANY_LOGO: '/assets/header_company_logo/small_logos/specterx-icon.png',
    API_URL: 'https://demo2-api.specterx.com',
    APP_URL: 'https://demo2-app.specterx.com',
    cognito: {
        USER_POOL_ID: 'eu-central-1_11ZeMYeVd',
        APP_CLIENT_ID: '5unklb9fh2oh804mkd0ugekpp7',
        IDENTITY_POOL_ID: 'eu-central-1:8e6cdd40-eb62-499d-a14d-53f10fc4bb32',
        DOMAIN: 'demo2-users.auth.eu-central-1.amazoncognito.com',
    },
    SSO: [SSOTypes.cognito, SSOTypes.google],
};

const dev: EnvConfig = {
    COMPANY_LOGO: '/assets/company_logo/Specter-X-Logo-Light-M.png',
    HEADER_COMPANY_LOGO: '/assets/header_company_logo/Specter-X-Logo-Dark.svg',
    HEADER_SMALL_COMPANY_LOGO: '/assets/header_company_logo/small_logos/specterx-icon.png',
    API_URL: 'https://dev-api.specterx.com',
    APP_URL: 'https://dev-app.specterx.com',
    cognito: {
        USER_POOL_ID: 'eu-central-1_4f3TZWBv1',
        APP_CLIENT_ID: '65fa7l3nh48aecks3c0vq0r6k5',
        IDENTITY_POOL_ID: 'eu-central-1:fba0a33d-513f-4983-81d7-27e570fa33d0',
        DOMAIN: 'dev-users.auth.eu-central-1.amazoncognito.com',
    },
    SSO: [SSOTypes.cognito, SSOTypes.microsoft, SSOTypes.google, SSOTypes.okta],
    DISABLE_GOOGLE_REGISTER_CHECK: false,
    ENABLE_OPSWAT_FILE_SCANNING: true,
};

const staging: EnvConfig = {
    COMPANY_LOGO: '/assets/company_logo/Specter-X-Logo-Light-M.png',
    HEADER_COMPANY_LOGO: '/assets/header_company_logo/Specter-X-Logo-Dark.svg',
    HEADER_SMALL_COMPANY_LOGO: '/assets/header_company_logo/small_logos/specterx-icon.png',
    API_URL: 'https://staging-api.specterx.com',
    APP_URL: 'https://staging-app.specterx.com',
    API_PROXY: {
        API_URL: 'https://spx-api.specterxlab.com',
        APP_URL: 'https://specterx.specterxlab.com',
        CHECK_APP_INFO_BEFORE_USING: true,
        DISABLE_MFA_BEHIND_PROXY: true,
        DISABLE_OTP_BEHIND_PROXY: true,
    },
    WSO: {
        CLIENT_SECRET: 'SnWenhqNR__HTrdVONEMin5LVA4a',
        CLIENT_ID: 'EvXFafsMY_d41Lffd6N_kFLma14a',
        DOMAIN: 'https://spx-auth.specterxlab.com',
        REDIRECT_URL: 'https://specterx.specterxlab.com',
    },
    cognito: {
        USER_POOL_ID: 'eu-central-1_2LXvsgmuz',
        APP_CLIENT_ID: '5bq1nn2o1t3d5fetq9r53k9i5l',
        IDENTITY_POOL_ID: 'eu-central-1:43b6caa7-7939-4773-8628-1caed8280df1',
        DOMAIN: 'staging-users.auth.eu-central-1.amazoncognito.com',
    },
    SSO: [SSOTypes.cognito, SSOTypes.microsoft, SSOTypes.google, SSOTypes.okta],
    DISABLE_GOOGLE_REGISTER_CHECK: false,
    ENABLE_IP_RESTRICTION: false,
    DISABLE_SELF_PHONE_FILLING: true,
    ENABLE_OPSWAT_FILE_SCANNING: false,
    IS_MULTITENANCY: false,
    ENABLE_SHARE_MESSAGE: true,
};

const prod: EnvConfig = {
    COMPANY_LOGO: '/assets/company_logo/Specter-X-Logo-Light-M.png',
    HEADER_COMPANY_LOGO: '/assets/header_company_logo/Specter-X-Logo-Dark.svg',
    HEADER_SMALL_COMPANY_LOGO: '/assets/header_company_logo/small_logos/specterx-icon.png',
    API_URL: 'https://api.specterx.com',
    APP_URL: 'https://app.specterx.com',
    cognito: {
        USER_POOL_ID: 'eu-central-1_kWvTMaXu7',
        APP_CLIENT_ID: '6ks76800dv7ot00i3evufatq0u',
        IDENTITY_POOL_ID: 'eu-central-1:4e0c2de4-4ff8-4113-9886-9dbb931c461c',
        DOMAIN: 'prod-users.auth.eu-central-1.amazoncognito.com',
    },
    DISABLE_GOOGLE_REGISTER_CHECK: false,
    SSO: [SSOTypes.cognito, SSOTypes.google],
    ENABLE_OPSWAT_FILE_SCANNING: false,
};

const share: EnvConfig = {
    COMPANY_LOGO: '/assets/company_logo/Specter-X-Logo-Light-M.png',
    HEADER_COMPANY_LOGO: '/assets/header_company_logo/Specter-X-Logo-Dark.svg',
    HEADER_SMALL_COMPANY_LOGO: '/assets/header_company_logo/small_logos/specterx-icon.png',
    API_URL: 'https://share-api.specterx.com',
    APP_URL: 'https://share.specterx.com',
    cognito: {
        USER_POOL_ID: 'eu-central-1_nNIl6Be0N',
        APP_CLIENT_ID: '2b9r5l5u2cdecv8kcbtah9q3rk',
        IDENTITY_POOL_ID: 'eu-central-1:4e0c2de4-4ff8-4113-9886-9dbb931c461c',
        DOMAIN: 'share-users.auth.eu-central-1.amazoncognito.com',
    },
    SSO: [SSOTypes.cognito, SSOTypes.google],
    DEFAULT_PHONE_CHECK: true
};

const migdal: EnvConfig = {
    COMPANY_LOGO: '/assets/company_logo/migdal-logo.png',
    HEADER_COMPANY_LOGO: '/assets/header_company_logo/migdal-logo-dark.png',
    HEADER_SMALL_COMPANY_LOGO: '/assets/header_company_logo/small_logos/migdal-logo-dark.png',
    API_URL: 'https://migdal-api.specterx.com',
    APP_URL: 'https://migdal.specterx.com',
    API_PROXY: {
        API_URL: 'https://spx-api.migdal-group.co.il',
        APP_URL: 'https://specterx.migdal-group.co.il',
        CHECK_APP_INFO_BEFORE_USING: true,
        DISABLE_MFA_BEHIND_PROXY: true,
        DISABLE_OTP_BEHIND_PROXY: true,
    },
    WSO: {
        CLIENT_SECRET: 'SnWenhqNR__HTrdVONEMin5LVA4a',
        CLIENT_ID: 'EvXFafsMY_d41Lffd6N_kFLma14a',
        DOMAIN: 'https://spx-auth.migdal-group.co.il',
        REDIRECT_URL: 'https://specterx.migdal-group.co.il',
    },
    cognito: {
        USER_POOL_ID: 'eu-central-1_30Cb6OZsI',
        APP_CLIENT_ID: '16p4e8dg7583fb3keefth7cu7l',
        IDENTITY_POOL_ID: 'eu-central-1:6947a515-e23a-4265-8156-e4570f41c15f',
        DOMAIN: 'migdal-users.auth.eu-central-1.amazoncognito.com',
    },
    SSO: [SSOTypes.cognito],
    ENABLE_WORKSPACE: false,
    ROUTES: APP_ROUTES_DEFAULT.filter((key) => key !== AppRoutes.userRoleEditor),
};

const ree: EnvConfig = {
    COMPANY_LOGO: '/assets/company_logo/ree-white.png',
    HEADER_COMPANY_LOGO: '/assets/header_company_logo/ree-black.png',
    HEADER_SMALL_COMPANY_LOGO: '/assets/header_company_logo/small_logos/specterx-icon.png',
    API_URL: 'https://rapi.specterx.com',
    APP_URL: 'https://ree.specterx.com',
    cognito: {
        USER_POOL_ID: 'eu-central-1_gNKwnOGxC',
        APP_CLIENT_ID: '15t7v5rs4k1c8618u8h50n1eg9',
        IDENTITY_POOL_ID: 'eu-central-1:1679225b-2007-40af-a4f7-638c519546ee',
        DOMAIN: 'rauth.specterx.com',
    },
    SSO: [SSOTypes.microsoft, SSOTypes.cognito],
};

const one1: EnvConfig = {
    COMPANY_LOGO: '/assets/company_logo/one1-white.png',
    HEADER_COMPANY_LOGO: '/assets/header_company_logo/one1-black.png',
    HEADER_SMALL_COMPANY_LOGO: '/assets/header_company_logo/small_logos/one1-black.png',
    API_URL: 'https://oapi.specterx.com',
    APP_URL: 'https://one1.specterx.com',
    cognito: {
        USER_POOL_ID: 'eu-central-1_EkuAOwam5',
        APP_CLIENT_ID: '16prfchvmotvruo2q8jet7rrjd',
        IDENTITY_POOL_ID: 'eu-central-1:27c97ecc-d159-4feb-8183-c429a6ad8df9',
        DOMAIN: 'oauth.specterx.com',
    },
    DISABLE_GOOGLE_REGISTER_CHECK: false,
    SSO: [SSOTypes.cognito, SSOTypes.google],
};

const varonis: EnvConfig = {
    COMPANY_LOGO: '/assets/company_logo/varonis-white.png',
    HEADER_COMPANY_LOGO: '/assets/header_company_logo/varonis-logo-dark.png',
    HEADER_SMALL_COMPANY_LOGO: '/assets/header_company_logo/small_logos/Varonis.png',
    API_URL: 'https://vapi.specterx.com',
    APP_URL: 'https://varonis.specterx.com',
    cognito: {
        USER_POOL_ID: 'eu-central-1_dgmtGoZRl',
        APP_CLIENT_ID: '2pgeaqnqv46oqs0oo2d7u7hhvt',
        IDENTITY_POOL_ID: 'eu-central-1:0bccc86c-2180-4bed-94b6-7ba46d3455b8',
        DOMAIN: 'vusers.auth.eu-central-1.amazoncognito.com',
    },
    SSO: [SSOTypes.okta, SSOTypes.cognito],
};

const nta: EnvConfig = {
    COMPANY_LOGO: '/assets/company_logo/nta.png',
    HEADER_COMPANY_LOGO: '/assets/header_company_logo/nta.png',
    HEADER_SMALL_COMPANY_LOGO: '/assets/header_company_logo/small_logos/nta.png',
    API_URL: 'https://nta-api.specterx.com',
    APP_URL: 'https://nta.specterx.com',
    cognito: {
        USER_POOL_ID: 'eu-central-1_WK7fghnBY',
        APP_CLIENT_ID: 'hfnnol1bfh7m3h04odu23pjb2',
        IDENTITY_POOL_ID: 'eu-central-1:cad3a146-04bc-4b3f-b89c-feba237f7503',
        DOMAIN: 'nta-users.auth.eu-central-1.amazoncognito.com',
    },
    SSO: [SSOTypes.microsoft],
    DEFAULT_LANGUAGE: LANGUAGES.he,
};

const shikunbinui: EnvConfig = {
    COMPANY_LOGO: '/assets/company_logo/shikunbinui-blue.png',
    HEADER_COMPANY_LOGO: '/assets/header_company_logo/shikunbinui-blue.png',
    HEADER_SMALL_COMPANY_LOGO: '/assets/header_company_logo/small_logos/shikunbinui-blue.png',
    API_URL: 'https://shikunbinui-api.specterx.com',
    APP_URL: 'https://shikunbinui.specterx.com',
    cognito: {
        USER_POOL_ID: 'eu-central-1_fC5uzAPGl',
        APP_CLIENT_ID: '20gibjfm5kaue16nli932q9b5u',
        IDENTITY_POOL_ID: 'eu-central-1:ca5a35a2-9977-457f-af9e-41d10aa85179',
        DOMAIN: 'shikunbinui-users.auth.eu-central-1.amazoncognito.com',
    },
    SSO: [SSOTypes.microsoft, SSOTypes.cognito],
    DEFAULT_LANGUAGE: LANGUAGES.he,
};

const meuhedet: EnvConfig = {
    COMPANY_LOGO: '/assets/company_logo/meuhedet.png',
    HEADER_COMPANY_LOGO: '/assets/header_company_logo/meuhedet.png',
    HEADER_SMALL_COMPANY_LOGO: '/assets/header_company_logo/small_logos/meuhedet.png',
    API_URL: 'https://meuhedet-api.specterx.com',
    APP_URL: 'https://meuhedet.specterx.com',
    cognito: {
        USER_POOL_ID: 'eu-central-1_jOakhw5F8',
        APP_CLIENT_ID: '7hv53ip54i2c4vr4g07q6v0grh',
        IDENTITY_POOL_ID: 'eu-central-1:c5a6bc23-c6b5-49f9-ba36-56aecbcc60fb',
        DOMAIN: 'meuhedet-users.auth.eu-central-1.amazoncognito.com',
    },
    API_PROXY: {
        API_URL: 'https://spx-api.meuhedet.co.il',
        APP_URL: 'https://specterx.meuhedet.co.il',
        CHECK_APP_INFO_BEFORE_USING: true,
        DISABLE_MFA_BEHIND_PROXY: true,
        DISABLE_OTP_BEHIND_PROXY: true,
    },
    SSO: [SSOTypes.microsoft, SSOTypes.cognito],
    SHOW_UPLOAD_DISCLAIMER: true,
    DEFAULT_LANGUAGE: LANGUAGES.he,
    ENABLE_WORKSPACE: true,
    DEFAULT_PHONE_CHECK: true
};

const msi: EnvConfig = {
    COMPANY_LOGO: '/assets/company_logo/msi-white.png',
    HEADER_COMPANY_LOGO: '/assets/header_company_logo/msi.png',
    HEADER_SMALL_COMPANY_LOGO: '/assets/header_company_logo/small_logos/specterx-icon.png',
    API_URL: 'https://msi-api.specterx.com',
    APP_URL: 'https://msi.specterx.com',
    cognito: {
        USER_POOL_ID: 'eu-central-1_JY8hFAJFI',
        APP_CLIENT_ID: '1j8a34bphhojvt4u189lathn0u',
        IDENTITY_POOL_ID: 'eu-central-1:73de62c8-9bac-475c-987e-dd95d7ecc135',
        DOMAIN: 'msi-users.auth.eu-central-1.amazoncognito.com',
    },
    SSO: [SSOTypes.microsoft, SSOTypes.cognito],
};

const gtc: EnvConfig = {
    COMPANY_LOGO: '/assets/company_logo/gtc-white.png',
    HEADER_COMPANY_LOGO: '/assets/header_company_logo/gtc-dark.png',
    HEADER_SMALL_COMPANY_LOGO: '/assets/header_company_logo/small_logos/gtc.png',
    API_URL: 'https://gtc-api.specterx.com',
    APP_URL: 'https://gtc.specterx.com',
    cognito: {
        USER_POOL_ID: 'eu-central-1_v4SQ4m1KD',
        APP_CLIENT_ID: '4o2n2rqun5pg0to2551glg024i',
        IDENTITY_POOL_ID: 'eu-central-1:47bd064a-3d4c-4f4a-9cfa-4bc2a3434127',
        DOMAIN: 'gtc-users.auth.eu-central-1.amazoncognito.com',
    },
    SSO: [SSOTypes.cognito, SSOTypes.microsoft],
};

const sevenci: EnvConfig = {
    COMPANY_LOGO: '/assets/company_logo/7ci-logo-white.png',
    HEADER_COMPANY_LOGO: '/assets/header_company_logo/7ci-logo-black.svg',
    HEADER_SMALL_COMPANY_LOGO: '/assets/header_company_logo/small_logos/7ci-logo-white.png',
    API_URL: 'https://7ci-api.specterx.com',
    APP_URL: 'https://7ci.specterx.com',
    cognito: {
        USER_POOL_ID: 'eu-central-1_B8xP7E6E9',
        APP_CLIENT_ID: '5pint5p597hui08lnlks08tehj',
        IDENTITY_POOL_ID: 'eu-central-1:1ba1833e-7d38-4609-a508-cf9727d06060',
        DOMAIN: '7ci-users.auth.eu-central-1.amazoncognito.com',
    },
    SSO: [SSOTypes.cognito, SSOTypes.microsoft],
};

const harel: EnvConfig = {
    COMPANY_LOGO: '/assets/company_logo/harel-logo-white.png',
    HEADER_COMPANY_LOGO: '/assets/header_company_logo/harel-logo-black.svg',
    HEADER_SMALL_COMPANY_LOGO: '/assets/header_company_logo/small_logos/specterx-icon.png',
    API_URL: 'https://harel-api.specterx.com',
    APP_URL: 'https://harel.specterx.com',
    cognito: {
        USER_POOL_ID: 'eu-central-1_oqyBG9tch',
        APP_CLIENT_ID: '7q3rh1b3t6kfd6fb1jnl6m6fmg',
        IDENTITY_POOL_ID: 'eu-central-1:a9d4794f-e82b-48cc-a540-4803dbd36486',
        DOMAIN: 'harel-users.auth.eu-central-1.amazoncognito.com',
    },
    SSO: [SSOTypes.cognito, SSOTypes.microsoft],
};

const incd: EnvConfig = {
    COMPANY_LOGO: '/assets/company_logo/incd.png',
    HEADER_COMPANY_LOGO: '/assets/header_company_logo/incd.png',
    HEADER_SMALL_COMPANY_LOGO: '/assets/header_company_logo/small_logos/incd.png',
    API_URL: 'https://incd-api.specterx.com',
    APP_URL: 'https://incd.specterx.com',
    cognito: {
        USER_POOL_ID: 'eu-central-1_YNA6iZK7D',
        APP_CLIENT_ID: '7atp5p4hinlk52n4dappn32tll',
        IDENTITY_POOL_ID: 'eu-central-1:c9a43482-b3c5-425e-8d18-5464875409df',
        DOMAIN: 'incd-users.auth.eu-central-1.amazoncognito.com',
    },
    SSO: [SSOTypes.google],
    ENABLE_IP_RESTRICTION: true,
    UPLOAD_FILES_COUNT_LIMIT: 50
};

const amitim: EnvConfig = {
    COMPANY_LOGO: '/assets/company_logo/amitim.png.png',
    HEADER_COMPANY_LOGO: '/assets/header_company_logo/amitim.png',
    HEADER_SMALL_COMPANY_LOGO: '/assets/header_company_logo/small_logos/amitim-icon.png',
    API_URL: 'https://amitim-api.specterx.com',
    APP_URL: 'https://amitim.specterx.com',
    API_PROXY: {
        API_URL: 'https://spx-api.mivtachim.co.il',
        APP_URL: 'https://specterx.mivtachim.co.il',
        CHECK_APP_INFO_BEFORE_USING: true,
        DISABLE_MFA_BEHIND_PROXY: true,
        DISABLE_OTP_BEHIND_PROXY: true,
    },
    WSO: {
        CLIENT_SECRET: 'SnWenhqNR__HTrdVONEMin5LVA4a',
        CLIENT_ID: 'EvXFafsMY_d41Lffd6N_kFLma14a',
        DOMAIN: 'https://spx-auth.mivtachim.co.il',
        REDIRECT_URL: 'https://specterx.mivtachim.co.il',
    },
    cognito: {
        USER_POOL_ID: 'eu-central-1_1Ry1WlXsr',
        APP_CLIENT_ID: '3ibn60qfb8oif75g096p4fo5o5',
        IDENTITY_POOL_ID: 'eu-central-1:4e0c2de4-4ff8-4113-9886-9dbb931c461c',
        DOMAIN: 'amitim-users.auth.eu-central-1.amazoncognito.com',
    },
    SSO: [SSOTypes.cognito],
    ENABLE_WORKSPACE: true,
    ENABLE_IP_RESTRICTION: true,
    SHOW_SHARE_BACK_BUTTON: true,
    ENABLE_SHARE_MESSAGE: true,
    ROUTES: APP_ROUTES_DEFAULT.filter((key) => key !== AppRoutes.userRoleEditor),
};

const mvs: EnvConfig = {
    COMPANY_LOGO: '/assets/company_logo/mvs.png',
    HEADER_COMPANY_LOGO: '/assets/header_company_logo/mvs.png',
    HEADER_SMALL_COMPANY_LOGO: '/assets/header_company_logo/small_logos/mvs-icon.png',
    API_URL: 'https://mvs-api.specterx.com',
    APP_URL: 'https://mvs.specterx.com',
    API_PROXY: {
        API_URL: 'https://spx-api.mvs.co.il',
        APP_URL: 'https://specterx.mvs.co.il',
        CHECK_APP_INFO_BEFORE_USING: true,
        DISABLE_MFA_BEHIND_PROXY: true,
        DISABLE_OTP_BEHIND_PROXY: true,
    },
    cognito: {
        USER_POOL_ID: 'eu-central-1_IXFmLTq5j',
        APP_CLIENT_ID: '6jnl81tj2d5ifqpqg5svnpglc5',
        IDENTITY_POOL_ID: 'eu-central-1:4e0c2de4-4ff8-4113-9886-9dbb931c461c',
        DOMAIN: 'mvs-users.auth.eu-central-1.amazoncognito.com',
    },
    WSO: {
        CLIENT_SECRET: 'SnWenhqNR__HTrdVONEMin5LVA4a',
        CLIENT_ID: 'EvXFafsMY_d41Lffd6N_kFLma14a',
        DOMAIN: 'https://spx-auth.mvs.co.il',
        REDIRECT_URL: 'https://specterx.mvs.co.il',
    },
    SSO: [SSOTypes.cognito],
    ENABLE_WORKSPACE: false,
    ENABLE_IP_RESTRICTION: true,
    SHOW_SHARE_BACK_BUTTON: false,
    ROUTES: APP_ROUTES_DEFAULT.filter((key) => key !== AppRoutes.userRoleEditor),
};

const plarium: EnvConfig = {
    COMPANY_LOGO: '/assets/company_logo/plarium-logo.png',
    HEADER_COMPANY_LOGO: '/assets/header_company_logo/plarium-logo.png',
    HEADER_SMALL_COMPANY_LOGO: '/assets/header_company_logo/small_logos/plarium-logo.png',
    API_URL: 'https://plarium-api.specterx.com',
    APP_URL: 'https://plarium.specterx.com',
    cognito: {
        USER_POOL_ID: 'eu-central-1_rs6vEDwTX',
        APP_CLIENT_ID: 'b43qloq0lfugh8btb8c94nf05',
        IDENTITY_POOL_ID: 'eu-central-1:4e0c2de4-4ff8-4113-9886-9dbb931c461c',
        DOMAIN: 'plarium-users.auth.eu-central-1.amazoncognito.com',
    },
    SSO: [SSOTypes.cognito, SSOTypes.google, SSOTypes.okta],
    ENABLE_IP_RESTRICTION: true,
    UPLOAD_FILES_COUNT_LIMIT: 50,
    DISABLE_GOOGLE_REGISTER_CHECK: false
};

const ips: EnvConfig = {
    COMPANY_LOGO: '/assets/company_logo/300is.png',
    HEADER_COMPANY_LOGO: '/assets/header_company_logo/300is.png',
    HEADER_SMALL_COMPANY_LOGO: '/assets/header_company_logo/small_logos/300is.png',
    API_URL: 'https://300is-api.specterx.com',
    APP_URL: 'https://300is.specterx.com',
    cognito: {
        USER_POOL_ID: 'eu-central-1_BoseH5AXR',
        APP_CLIENT_ID: '5qodqj5tg4cfoge20u9c89il09',
        IDENTITY_POOL_ID: 'eu-central-1:4e0c2de4-4ff8-4113-9886-9dbb931c461c',
        DOMAIN: '300is-users.auth.eu-central-1.amazoncognito.com',
    },
    SSO: [SSOTypes.cognito],
    ENABLE_IP_RESTRICTION: true,
    UPLOAD_FILES_COUNT_LIMIT: 50
};

let config: EnvConfig;

if (import.meta.env.VITE_STAGE === 'prod') {
    config = prod;
} else if (import.meta.env.VITE_STAGE === 'prod-incd') {
    config = incd;
} else if (import.meta.env.VITE_STAGE === 'prod-migdal') {
    config = migdal;
} else if (import.meta.env.VITE_STAGE === 'prod-nta') {
    config = nta;
} else if (import.meta.env.VITE_STAGE === 'prod-shikunbinui') {
    config = shikunbinui;
} else if (import.meta.env.VITE_STAGE === 'prod-meuhedet') {
    config = meuhedet;
} else if (import.meta.env.VITE_STAGE === 'prod-msi') {
    config = msi;
} else if (import.meta.env.VITE_STAGE === 'prod-gtc') {
    config = gtc;
} else if (import.meta.env.VITE_STAGE === 'prod-7ci') {
    config = sevenci;
} else if (import.meta.env.VITE_STAGE === 'prod-harel') {
    config = harel;
} else if (import.meta.env.VITE_STAGE === 'demo2') {
    config = demo2;
} else if (import.meta.env.VITE_STAGE === 'prod-share') {
    config = share;
} else if (import.meta.env.VITE_STAGE === 'prod-varonis') {
    config = varonis;
} else if (import.meta.env.VITE_STAGE === 'prod-one1') {
    config = one1;
} else if (import.meta.env.VITE_STAGE === 'prod-ree') {
    config = ree;
} else if (import.meta.env.VITE_STAGE === 'staging') {
    config = staging;
} else if (import.meta.env.VITE_STAGE === 'dev') {
    config = dev;
} else if (import.meta.env.VITE_STAGE === 'local') {
    config = local;
} else if (import.meta.env.VITE_STAGE === 'demo') {
    config = demo;
} else if (import.meta.env.VITE_STAGE === 'prod-amitim') {
    config = amitim;
} else if (import.meta.env.VITE_STAGE === 'prod-mvs') {
    config = mvs;
} else if (import.meta.env.VITE_STAGE === 'prod-plarium') {
    config = plarium;
} else if (import.meta.env.VITE_STAGE === 'prod-300is') {
    config = ips;
} else {
    config = dev;
}

const appConfig: Readonly<EnvConfig> = {
    // Add common config values here

    DEFAULT_LANGUAGE: LANGUAGES.en,
    HAS_FULL_OTP_AUTH: true,
    SHOW_FEDERATED_POPUP: false,
    DISABLE_GOOGLE_REGISTER_CHECK: true,
    DISABLE_CREATE_FOLDER: false,
    HIDE_SHARED_FILE_POLICIES: false,
    DISALLOW_SHARE_WHILE_FILE_UPLOADING: false,
    HIDE_FAQ_BUTTON: true,
    HIDE_UPLOAD_BUTTON: false,
    ENABLE_WORKSPACE: true,
    ROUTES: APP_ROUTES_DEFAULT,
    DISABLE_SELF_PHONE_FILLING: true,
    SHOW_SHARE_BACK_BUTTON: true,
    IS_MULTITENANCY: false,
    ENABLE_SHARE_MESSAGE: false,
    ...config,
};

export const IS_WSO = 'WSO' in config;
export const HAS_API_PROXY = 'API_PROXY' in config;

export const IS_LOCAL_ENV = import.meta.env.DEV;
export const IS_NON_PROD_ENV = IS_LOCAL_ENV || ['staging', 'dev'].includes(import.meta.env.VITE_STAGE);
export const IS_UNSTABLE_ENV = IS_LOCAL_ENV || import.meta.env.VITE_STAGE === 'dev';

export const IS_ONLY_SSO = appConfig.SSO.length === 1;
export const ALL_SSO: SSOTypes[] = Object.values(SSOTypes);
export const SSO_ORDER: Partial<Record<SSOTypes, number>> = {
    [SSOTypes.microsoft]: 1,
    [SSOTypes.okta]: 2,
    [SSOTypes.google]: 0,
};

Object.freeze(appConfig);

export default appConfig;
