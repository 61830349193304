import { PolicySettingsAll } from '../../types/types';
import { OpenOptions } from '../../config/openOptions';

export enum Operations {
    open = 'open',
    login = 'login',
    download = 'download',
    upload = 'upload',
    export = 'export',
    signup = 'signup',
    logout = 'logout',
    create = 'create',
    delete = 'delete',
    edit = 'edit',
    permissionsadded = 'permissionsadded',
    createpolicy = 'createpolicy',
    setpolicy = 'setpolicy',
    editpolicy = 'editpolicy',
    permissionsremoved = 'permissionsremoved',
    deleteuser = 'deleteuser',
    sharingrequestblocked = 'sharingrequestblocked',
    accessdenied = 'accessdenied',
    sharingrequestapproved = 'sharingrequestapproved',
    modifyuserrole = 'modifyuserrole',
    loginfailed = 'loginfailed',
    possibleleak = 'possibleleak',
    filerequest = 'filerequest',
    blocked = 'blocked',
    adduserstogroup = 'adduserstogroup',
    removeusersfromgroup = 'removeusersfromgroup',
}

export type AccessApps = OpenOptions | 'Salesforce' | 'sharepoint';

/* eslint-disable camelcase */
interface DeleteUserDetails {
    deleted_user_id: string;
    deleted_user_email: string;
}

interface UserGroupOperationDetails {
    group_id: string;
    group_name: string;
}

export interface LogRecord {
    operation: Operations;
    tenant: string;
    timestamp: string;
    user: string;
    app?: AccessApps;
    details?: PolicySettingsAll | DeleteUserDetails | UserGroupOperationDetails;
    file_id?: string;
    file_name?: string;
    file_size?: string;
    origin_id?: string;
    owner_id?: string;
    policy_id?: string;
    policy_name?: string;
    affected_users?: string[];
    user_agent?: string;
    user_ip?: string;
    child_id?: string;
    child_name?: string;
}
/* eslint-enable camelcase */

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
export type PageSize = 10 | 15 | 25 | 50;

export interface PaginationState {
    currentPage: number;
    nextPageToken: string;
    pageSize: PageSize;
}

export interface GetAuditsParams {
    page?: number;
    restRecords?: number;
}

export interface FiltersState {
    searchedUser: string;
    searchedFilename: string;
    selectedOperation: Operations | '';
}

export type SelectedOperation = Operations | '';

export type FilterTypes = keyof FiltersState;

export type ChangeFilters = (filterType: FilterTypes, value: string) => void;

export interface AuditsResponse {
    results: LogRecord[];
    page: number;
    pageSize: number;
    next?: string;
}

export enum FormFields {
    searchedUser = 'searchedUser',
    selectedOperation = 'selectedOperation',
    searchedFilename = 'searchedFilename',
}

export interface FormFieldsChange {
    [FormFields.searchedUser]: string;
    [FormFields.searchedFilename]: string;
    [FormFields.selectedOperation]: SelectedOperation;
}

export interface AuditsRequestConfig {
    recordsCount: number;
    allowInParallel: boolean;
    next?: string;
}

export interface GenerateBecomeLicensedUserEmailRequestConfig {
    newLeadEmail: string;
    newLeadRealLoginEmail: string;
}
