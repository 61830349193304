import React, { FC } from 'react';

import { Button, Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { useStores } from '../../../../hooks';
import onDeleteFile from '../../../../FilesTable/onDeleteFile';
import { batchActionsNamespace } from '../nameSpace';
import styles from './BatchDeleteButton.module.scss';

const buttonsNamespace = 'general.buttons';

const BatchDeleteButton: FC = observer(() => {
    const { filesListStore } = useStores();

    const { t } = useTranslation();

    return (
        <Popconfirm
            placement="topLeft"
            title={t(`${batchActionsNamespace}.deleteAlert`)}
            onConfirm={() => filesListStore.deleteSelectedFiles(onDeleteFile)}
            okText={t(`${buttonsNamespace}.yes`)}
            cancelText={t(`${buttonsNamespace}.no`)}
        >
            <div className={styles.delete}>
                <Button className={styles['delete-button']}>
                    <DeleteOutlined className={styles['delete-icon']} />
                    {t(`${batchActionsNamespace}.delete`)}
                </Button>
            </div>
        </Popconfirm>
    );
});

export default BatchDeleteButton;
