import React, { Component } from 'react';

import {
    Button, message, Popconfirm, Typography,
} from 'antd';
import { inject, observer } from 'mobx-react';
import { DeleteOutlined, CopyOutlined } from '@ant-design/icons';

import i18n from '@/content';
import { AuthSettingsStore, PolicyStore } from '../../../../stores';
import { Policy } from '../../../../types/types';
import { PolicyChangeRequestBody } from '../interfaces';
import { BASEURL, ENDPOINTS } from '../../../../api';
import {
    captureErrorForSentry,
    checkIs404,
    checkIsSpecificCode,
} from '../../../utils';
import {
    expandSettings,
    findIncompatibleSettings,
    getDaysRangeSetting,
} from '../../../../stores/PolicyStore/helpers';
import {
    checkSettingsDifference,
    findPolicyNameErrors,
    getRequestBody,
    validateIntRange,
} from '../helpers';
import { DAYS_RETENTION_RANGE } from '../../../../stores/PolicyStore/dataRetention';
import styles from './PolicyButtons.module.scss';

const { Text } = Typography;

const WARNING_MESSAGE_DURATION = 8;

interface PolicySettingsProps {
    policyStore?: PolicyStore;
    authSettingsStore?: AuthSettingsStore;
}

@inject('authSettingsStore', 'policyStore')
@observer
class PolicyButtons extends Component<PolicySettingsProps> {
    private readonly nameSpace = 'policyEditor';

    private validateDaysRetention(): boolean {
        const { policyStore: { changedPolicy } } = this.props;
        const daysRangeSetting = getDaysRangeSetting(changedPolicy);
        let result: boolean;
        if (!daysRangeSetting) {
            result = true;
        } else {
            const { min, max } = DAYS_RETENTION_RANGE;
            result = validateIntRange(daysRangeSetting.value as number, min, max);
        }
        return result;
    }

    private checkIsSubmitDisabled(): boolean {
        const { policyStore: { selectedPolicy, changedPolicy } } = this.props;
        return checkSettingsDifference(selectedPolicy, changedPolicy) && this.validateDaysRetention();
    }

    // TODO: move this logic into PolicyStore
    handleSubmit = async (): Promise<void> => {
        const { nameSpace } = this;
        const { policyStore, authSettingsStore } = this.props;
        const {
            setLoadingPolicy,
            fetchPolicyList,
            selectedPolicy,
            changedPolicy,
            policyList,
            selectPolicyForEditing,
        } = policyStore;
        const expandedSelectedPolicy = expandSettings(selectedPolicy, 'access');
        const expandedEditedPolicy = expandSettings(changedPolicy, 'access');
        const { API } = authSettingsStore;
        const isNew = !selectedPolicy.id;
        const requestBody: PolicyChangeRequestBody = getRequestBody(
            expandedSelectedPolicy,
            expandedEditedPolicy,
            isNew,
        );
        const messagesNameSpace = `${nameSpace}.messages`;
        const incompatibleSetting = findIncompatibleSettings(expandedEditedPolicy.settings.access);
        message.destroy();
        const policyNameError = isNew ? findPolicyNameErrors(requestBody.title, policyList) : null;
        if (policyNameError) {
            message.warning(
                i18n.t(`${messagesNameSpace}.${policyNameError}`),
                WARNING_MESSAGE_DURATION,
            );
        } else if (incompatibleSetting?.i18nMessageKey) {
            message.warning(
                i18n.t(`${messagesNameSpace}.incompatibleSettingsWarnings.${incompatibleSetting.i18nMessageKey}`),
                WARNING_MESSAGE_DURATION,
            );
        } else {
            let newPolicy: Policy = null;
            try {
                setLoadingPolicy(true);
                if (isNew) {
                    newPolicy = await API.post(
                        BASEURL.backend(),
                        ENDPOINTS.policy(),
                        { body: requestBody },
                    );
                } else {
                    await API.patch(
                        BASEURL.backend(),
                        ENDPOINTS.policy(selectedPolicy.id),
                        { body: requestBody },
                    );
                }
                message.success(i18n.t(`${messagesNameSpace}.${isNew ? 'savedSuccess' : 'updatedSuccess'}`));
                await fetchPolicyList({ isSilently: true });
                if (newPolicy) {
                    policyStore.tryAddPolicyToList(newPolicy);
                }
                selectPolicyForEditing(isNew && newPolicy ? newPolicy.id : selectedPolicy.id);
            } catch (error) {
                console.log('error is', error);
                if (checkIsSpecificCode(error, 409)) {
                    message.warning(i18n.t(`${messagesNameSpace}.alreadyExists`));
                } else {
                    captureErrorForSentry(error, 'PolicySettings.handleSubmit');
                    message.error(i18n.t(`${messagesNameSpace}.${isNew ? 'saveError' : 'updateError'}`));
                }
            }
            setLoadingPolicy(false);
        }
    }

    private onDeleteSuccess(policyId: string): void {
        const { nameSpace } = this;
        const { policyStore: { removePolicyFromList, selectPolicyForEditing } } = this.props;
        message.success(i18n.t(`${nameSpace}.messages.deleteSuccess`));
        removePolicyFromList(policyId);
        selectPolicyForEditing();
    }

    handleDelete = async (): Promise<void> => {
        const { nameSpace } = this;

        const { policyStore, authSettingsStore } = this.props;
        const { API } = authSettingsStore;
        const { setLoadingPolicy, selectedPolicy } = policyStore;
        if (selectedPolicy) {
            const messagesNameSpace = `${nameSpace}.messages`;
            const { id } = selectedPolicy;
            try {
                setLoadingPolicy(true);
                await API.del(BASEURL.backend(), ENDPOINTS.policy(id), {});
                this.onDeleteSuccess(id);
            } catch (error) {
                if (checkIs404(error)) {
                    this.onDeleteSuccess(id);
                } else {
                    captureErrorForSentry(error, 'PolicySettings.handleDelete');
                    message.error(i18n.t(`${messagesNameSpace}.deleteError`));
                }
            }
            setLoadingPolicy(false);
        }
    }

    handleDuplicate = (): void => {
        const { policyStore: { selectedPolicy, setPolicyForEditing } } = this.props;
        const newPolicySnapshot: Policy = {
            ...selectedPolicy,
            title: `${selectedPolicy.title} (1)`,
            id: '',
        };
        setPolicyForEditing(newPolicySnapshot);
    }

    render(): JSX.Element {
        const { nameSpace } = this;
        const { policyStore: { selectedPolicy } } = this.props;
        if (!selectedPolicy) {
            return <></>;
        }
        const applyDisabled = !this.checkIsSubmitDisabled();
        const isDeleteDisabled = !selectedPolicy.deletable;

        return (
            <div className={styles['policy-settings-buttons']}>
                {!selectedPolicy.id
                    ? (
                        <Button
                            type="primary"
                            disabled={!this.validateDaysRetention()}
                            onClick={this.handleSubmit}
                            className={styles['primary-button']}
                            data-testid="policyEditor_applyChagesNewPolicy"
                        >
                            {i18n.t('general.buttons.save')}
                        </Button>
                    )
                    : (
                        <>
                            <Button
                                type="primary"
                                onClick={this.handleSubmit}
                                disabled={applyDisabled}
                                className={styles['primary-button']}
                                data-testid="policyEditor_applyChangesEditPolicy"
                            >
                                {i18n.t('general.buttons.apply')}
                            </Button>
                            <Popconfirm
                                placement="topLeft"
                                disabled={isDeleteDisabled}
                                title={<Text>{i18n.t(`${nameSpace}.deleteConfirm`)}</Text>}
                                onConfirm={this.handleDelete}
                                okText={i18n.t('general.buttons.confirm')}
                                cancelText={i18n.t('general.buttons.cancel')}
                            >
                                <Button
                                    disabled={isDeleteDisabled}
                                    type="link"
                                    className={styles['popup-trigger-button']}
                                    data-testid="PolicyEditor_deleteButton"
                                >
                                    <DeleteOutlined className={styles.icon} />
                                    {i18n.t('general.buttons.delete')}
                                </Button>
                            </Popconfirm>

                            <Button
                                type="link"
                                onClick={this.handleDuplicate}
                                className={styles['secondary-button']}
                            >
                                <CopyOutlined className={styles.icon} />
                                {i18n.t(`${nameSpace}.duplicate`)}
                            </Button>
                        </>
                    )}
            </div>
        );
    }
}

export default PolicyButtons;
