import React from 'react';

import { Navigate, Route, Routes } from 'react-router';
import { message } from 'antd';
import { Trans } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { Auth } from 'aws-amplify';

import i18n from '@/content';
import { Loading } from '../../Common/Loading';
import SignIn from '../SignIn';
import SignUp from '../SignUp';
import ForgotPassword from '../ForgotPassword';
import ConfirmUser from '../ConfirmUser';
import { PublicFooter, PublicHeader } from '../../Common/PublicComponents';
import OTPSignIn from '../OTPSignIn';
import { withRouter, WithRouterProps } from '@/components/HOC';
import { CognitoAuthError, CognitoErrorCodes } from '@/types/types';
import { IAuthenticatorChildrenProps, SignInOptions } from '../interfaces';
import { authErrorMessageToI18nPayload, captureCognitoError, onSpacePress } from '../../utils';
import './AuthContainer.scss';

const emailConfirmationNameSpace = 'emailConfirmation';
export const LONG_MESSAGE_DURATION_SECONDS = 60;

export interface AuthContainerProps extends IAuthenticatorChildrenProps, WithRouterProps {}

export interface AuthContainerState {
    isNotConfirmed: boolean;
    confirmationUsername: string;
    userName: string;
    userPassword: string;
}

@inject('appStore')
@observer
class AuthContainer extends React.Component<IAuthenticatorChildrenProps & WithRouterProps, AuthContainerState> {
    constructor(props) {
        super(props);
        this.state = {
            isNotConfirmed: false,
            confirmationUsername: '',
            userName: '',
            userPassword: '',
        };
    }

    componentDidUpdate(prevProps: Readonly<AuthContainerProps>): void {
        const { location: prevLocation } = prevProps;
        const { location, appStore: { setErrorStatus } } = this.props;
        if (location !== prevLocation) {
            setErrorStatus(false);
            this.setState({ isNotConfirmed: false, confirmationUsername: '' });
        }
    }

    componentWillUnmount(): void {
        const { appStore } = this.props;
        appStore.setErrorStatus(false);
    }

    clickConfirm = async (): Promise<void> => {
        const { appStore: { setLoadingStatus, setErrorStatus } } = this.props;
        const { confirmationUsername } = this.state;

        try {
            setLoadingStatus(true, i18n.t(`${emailConfirmationNameSpace}.sendingCode`));
            await Auth.resendSignUp(confirmationUsername);
            setErrorStatus(false);
            this.setState({ isNotConfirmed: false, confirmationUsername: '' });
            message.success(i18n.t(`${emailConfirmationNameSpace}.pleaseCheckEmail`));
        } catch (error) {
            captureCognitoError(error, 'AuthContainer.clickConfirm');
            message.error(i18n.t(`${emailConfirmationNameSpace}.couldNotSend`, { confirmationUsername }));
        } finally {
            setLoadingStatus(false);
        }
    }

    signInCalls = async (
        authWorker: () => Promise<any>,
        nameSpace: string,
        options: SignInOptions,
    ): Promise<boolean> => {
        const { username, isFederated, password } = options;
        const { appStore } = this.props;
        const { setLoadingStatus, setErrorStatus } = appStore;

        try {
            this.setState({ isNotConfirmed: false, confirmationUsername: '' });
            setErrorStatus(false);
            setLoadingStatus(true, i18n.t(`${nameSpace}.loading`));
            const user = await authWorker();

            if (user && nameSpace === 'signUp') {
                message.success(i18n.t(`${emailConfirmationNameSpace}.emailSent`), LONG_MESSAGE_DURATION_SECONDS);
            }

            // We shouldn't stop loading for federated sign in, because in this case we got response before redirect
            if (!isFederated) {
                setLoadingStatus(false);
            }
            return true;
        } catch (error) {
            captureCognitoError(error, 'AuthContainer.signInCalls');
            console.log('signInCalls error', error);
            const { code: errorCode, message: cognitoErrorMessage } = error as CognitoAuthError;
            let errorMessage = '';
            if (errorCode === CognitoErrorCodes.UsernameExistsException) {
                this.setState({ userName: username, userPassword: password });
                await Auth.forgotPassword(username.toLowerCase());
                this.props.navigate('/confirmUser')
                setLoadingStatus(false);
                return null;
            }
            if (errorCode === CognitoErrorCodes.UserNotConfirmedException) {
                this.setState({ isNotConfirmed: true, confirmationUsername: username });
            } else {
                const [key, i18nOption] = authErrorMessageToI18nPayload(cognitoErrorMessage, () => {
                    const i18nErrorCode = nameSpace === 'signIn' ? `${errorCode}SignIn` : errorCode;
                    const errorType = i18n.exists(`auth.errors.${i18nErrorCode}`)
                        ? i18nErrorCode
                        : 'serverIssue';
                    return [`auth.errors.${errorType}`];
                });
                errorMessage = i18n.t(key, i18nOption);
            }

            if (nameSpace !== 'Otp') {
                setErrorStatus(true, '', errorMessage || cognitoErrorMessage);
            }
            setLoadingStatus(false);

            return false;
        }
    }

    render(): JSX.Element {
        const { props } = this;
        const { isNotConfirmed, userName, userPassword } = this.state;
        const { errorStatus, loadingStatus } = props.appStore;
        const { hasError, errorMessage } = errorStatus;

        const baseSignInPageProps = {
            signInCalls: this.signInCalls,
            ...props,
        };
        return (
            <div className="auth-container">
                <div className="root-auth-container">
                    <PublicHeader />
                    <div className="content-wrapper">
                        {loadingStatus.isLoading && <Loading fullScreen loadingText="Please wait..." />}
                        <Routes>
                            <Route
                                path="/signIn"
                                element={<SignIn {...baseSignInPageProps} />}
                            />
                            <Route
                                path="/gdrive/share"
                                element={<SignIn gdrive {...baseSignInPageProps} />}
                            />
                            <Route
                                path="/box/share"
                                element={<SignIn box {...baseSignInPageProps} />}
                            />
                            <Route
                                path="/signUp"
                                element={<SignUp signInCalls={this.signInCalls} {...props} />}
                            />
                            <Route
                                path="/forgotPassword"
                                element={<ForgotPassword signInCalls={this.signInCalls} />}
                            />
                            <Route
                                path="/OTPSignIn"
                                element={<OTPSignIn />}
                            />
                            <Route
                                path="/confirmUser"
                                element={<ConfirmUser userName={userName} userPassword={userPassword}/>}
                            />
                            <Route
                                path="*"
                                element={(
                                    <Navigate
                                        to={{ search: window.location.search, pathname: '/signIn' }}
                                        replace
                                    />
                                )}
                            />
                        </Routes>
                        {hasError && (
                            <div className="error-container">
                                {isNotConfirmed
                                    ? (
                                        <Trans i18nKey="auth.errors.notConfirmed">
                                            Please confirm email
                                            <span
                                                role="button"
                                                tabIndex={0}
                                                key="check"
                                                className="confirmation"
                                                onClick={this.clickConfirm}
                                                onKeyUp={(event) => onSpacePress(event, this.clickConfirm)}
                                            >
                                                Resend code
                                            </span>
                                            if you have not got one
                                        </Trans>
                                    )
                                    : <span>{errorMessage}</span>}
                            </div>
                        )}
                    </div>
                    <PublicFooter />
                </div>
            </div>
        );
    }
}

export default withRouter<IAuthenticatorChildrenProps>(AuthContainer);
