import { AppStore } from '@/stores';
import { AuthStates } from '@/types/types';

export interface SignInOptions {
    authState?: string;
    username?: string;
    isFederated?: boolean;
    password?: string;
}

// TODO: refactor all these any to real types; remove invalid props
export interface IAuthenticatorChildrenProps {
    signInCalls?: (signInCall: () => Promise<any>, nameSpace: string, options: SignInOptions) => Promise<boolean>;
    nameSpace?: string
    authState?: AuthStates;
    appStore?: AppStore,
    gdrive?: boolean,
    box?: boolean,
}

export type AppAuthenticatorProps = Omit<IAuthenticatorChildrenProps, 'authState'>;

/* eslint-disable camelcase */
export enum CognitoAuthEvents {
    signIn = 'signIn',
    signOut = 'signOut',
    signUp = 'signUp',
    tokenRefresh = 'tokenRefresh',
    tokenRefresh_failure = 'tokenRefresh_failure',
}
