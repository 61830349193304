import React, { Component, SyntheticEvent } from 'react';

import { Navigate } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Auth } from 'aws-amplify';
import { ICredentials } from 'aws-amplify/lib/Common/types/types';
// eslint-disable-next-line import/no-extraneous-dependencies
import { CognitoUser } from 'amazon-cognito-identity-js';

import { PasswordVisibilityToggle, SpInput } from '@/components/Common/Sp';
import { AUTO_SIGN_IN_INITIATED } from '@/consts';
import i18n from '@/content';
import TabTitle from '../../Common/TabTitle';
import FederatedButton from '../FederatedButton';
import AuthSubmitButton from '../AuthSubmitButton';
import AuthPageTitle from '../AuthPageTitle';
import { AuthToggleLink, BaseAuthLink } from '../AuthLink';
import AltAuthSeparator from '../AltAuthSeparator';
import appConfig, { SSOTypes, SSO_ORDER } from '../../../config/env';
import { MINI_APPS_PATH_NAMES_LIST, MiniAppRoutes } from '@/config/appRoutes';
import { AuthStates } from '@/types/types';
import { IS_INSIDE_IFRAME } from '@/config/browserEnv';
import { AuthContainerProps } from '../AuthContainer/AuthContainer';
import { toTitleCase } from '../../utils';
import { getSingInQueryParam, extractSavedFederatedProvider } from '../helpers';
import {IS_TEST_MODE} from '@/components/AppAuthenticator/AppAuthenticator';
import './Signin.scss';

const checkHasCognito = (SSOOptions: SSOTypes[]): boolean => SSOOptions.includes(SSOTypes.cognito);

const getSSOToDisplay = (extraSSO: SSOTypes[], isExtraSSOAllowed): SSOTypes[] => {
    const allSSO = isExtraSSOAllowed ? [...extraSSO, ...appConfig.SSO] : [...appConfig.SSO];
    allSSO.sort((current, prev) => SSO_ORDER[current] - SSO_ORDER[prev]);
    return allSSO;
};

const getExtraSSO = (search: string): SSOTypes[] => {
    const queryParams = new URLSearchParams(search);
    const additionalAuthParams = queryParams.get('auth');

    if (!additionalAuthParams) {
        return [];
    }
    const supportedSSO = Object.keys(SSOTypes);
    const uniqueParams = [...new Set<SSOTypes>((additionalAuthParams.split(',') as SSOTypes[]))];

    return uniqueParams.filter((item) => (
        supportedSSO.includes(item)
        && !appConfig.SSO.includes(item)
    ));
};

interface SignInState {
    email: string;
    password: string;
    isValid: boolean;
    showPass: boolean;
    isExtraSSOAllowed: boolean;
    extraSSO: SSOTypes[];
}

@inject('appStore')
@observer
class SignIn extends Component<AuthContainerProps, SignInState> {
    private readonly nameSpace = 'signIn';

    private documentInput = '';

    constructor(props: AuthContainerProps) {
        super(props);
        this.state = {
            email: '',
            password: '',
            isValid: false,
            showPass: false,
            isExtraSSOAllowed: false,
            extraSSO: [],
        };
    }

    async componentDidMount(): Promise<void> {
        const { appStore, location } = this.props;
        const {
            setLoadingStatus,
            userStore: { hasDoubleAuthError },
        } = appStore;
        const extraSSO = getExtraSSO(location.search);
        this.setState({ extraSSO });
        const wasSignedOut = this.checkWasSignedOut();
        localStorage.removeItem('signedOut');
        const querySingIn = getSingInQueryParam();
        if (!IS_INSIDE_IFRAME && (hasDoubleAuthError || querySingIn)) {
            setLoadingStatus(true);
            const federatedProvider = hasDoubleAuthError ? extractSavedFederatedProvider() : querySingIn;
            if (federatedProvider) {
                await this.doFederatedSignIn(federatedProvider);
            }
        } else if (this.checkShouldStartSignInImmediately([...extraSSO, ...appConfig.SSO], wasSignedOut)) {
            sessionStorage.setItem(AUTO_SIGN_IN_INITIATED, String(true));
            setLoadingStatus(true);
            await this.doFederatedSignIn(appConfig.SSO[0]);
        }
        if (IS_TEST_MODE) {
            this.setState({ isExtraSSOAllowed: true });
        }
    }

    private checkWasSignedOut = (): boolean => {
        const signedOut = localStorage.getItem('signedOut');
        return signedOut === String(true);
    }

    private checkShouldStartSignInImmediately = (possibleSSOList: SSOTypes[], wasSignedOut: boolean): boolean => {
        const { location, appStore } = this.props;
        const { errorStatus: { hasError } } = appStore;
        const cognitoExist = checkHasCognito(possibleSSOList);
        const hasOnlySSO = possibleSSOList.length === 1;
        const nextRoute = new URLSearchParams(location.search).get('next');
        const isMiniAppNext = MINI_APPS_PATH_NAMES_LIST.includes(nextRoute as MiniAppRoutes);
        return !IS_INSIDE_IFRAME && !cognitoExist && hasOnlySSO && !hasError && (!wasSignedOut || isMiniAppNext);
    }

    private validateForm(): void {
        const { email, password } = this.state;

        this.setState({
            // Password is coming to this function with one char less for some reason
            isValid: !!email.length && !!password.length,
        });
    }

    onEmailChange = (email: string): void => {
        this.setState({ email }, () => this.validateForm());
    }

    onPasswordChange = (password: string): void => {
        this.setState({ password }, () => this.validateForm());
    }

    doLogin = async (event: SyntheticEvent): Promise<void> => {
        event.preventDefault();
        const { password, email } = this.state;
        const emailLowerCase = email.toLowerCase();
        const { signInCalls } = this.props;

        const callBackFuncFeder = (): Promise<CognitoUser> => Auth.signIn(emailLowerCase, password);

        try {
            await signInCalls(
                callBackFuncFeder,
                this.nameSpace,
                { authState: AuthStates.authenticated, username: emailLowerCase },
            );
        } catch (error) {
            console.log(error);
        }
    }

    doFederatedSignIn = async (provider: string): Promise<void> => {
        const { signInCalls } = this.props;
        const callBackFunc = (): Promise<ICredentials> => (
            Auth.federatedSignIn({ customProvider: toTitleCase(provider) })
        );
        await signInCalls(callBackFunc, this.nameSpace, { isFederated: true });
    }

    togglePasswordVisibility = (event: SyntheticEvent): void => {
        event.preventDefault();
        event.stopPropagation();
        this.setState(({ showPass }) => ({ showPass: !showPass }));
    }

    render(): JSX.Element {
        const {
            email, password, isValid, showPass, isExtraSSOAllowed, extraSSO,
        } = this.state;
        const { authState } = this.props;
        const { nameSpace } = this;
        const SSOToDisplay = getSSOToDisplay(extraSSO, isExtraSSOAllowed);
        const cognitoExist = checkHasCognito(SSOToDisplay);
        const inputType = showPass ? 'text' : 'password';

        if (authState === AuthStates.authenticated) {
            return <Navigate to="/" />;
        }

        return (
            <div className={`sign-in-wrapper ${!cognitoExist ? 'federated-only' : ''}`}>
                <TabTitle title={i18n.t(`${nameSpace}.tabTitle`)} />
                <AuthPageTitle titleKey={`${nameSpace}.pageTitle`} />

                {SSOToDisplay.map((type) => (
                    type !== SSOTypes.cognito && (
                        <FederatedButton
                            isSignIn
                            key={type}
                            doFederatedSignIn={this.doFederatedSignIn}
                            type={type}
                        />
                    )
                ))}

                {cognitoExist && (
                    <div key="cognito">

                        {SSOToDisplay.length > 1
                        && (
                            <AltAuthSeparator message={i18n.t(`${nameSpace}.orLogin`)} />
                        )}

                        <div>
                            <form onSubmit={this.doLogin}>
                                <SpInput
                                    type="text"
                                    value={email}
                                    placeholder={i18n.t(`${nameSpace}.emailPlaceholder`)}
                                    onChange={this.onEmailChange}
                                    autoComplete="username"
                                    data-testid="signIn_email"
                                />
                                <div className="input-show-wrapper">
                                    <SpInput
                                        type={inputType}
                                        value={password}
                                        placeholder={i18n.t(`${nameSpace}.passwordPlaceholder`)}
                                        onChange={this.onPasswordChange}
                                        autoComplete="current-password"
                                        data-testid="signIn_password"
                                    />
                                    <PasswordVisibilityToggle
                                        toggleVisibility={this.togglePasswordVisibility}
                                        className="show"
                                    />
                                </div>
                                <AuthSubmitButton
                                    disabled={!isValid}
                                    onClick={this.doLogin}
                                    titleKey={`${nameSpace}.login`}
                                    data-testid="signIn_submit"
                                />
                            </form>
                        </div>

                        <BaseAuthLink href="/forgotPassword">
                            {i18n.t(`${nameSpace}.forgotPassword`)}
                        </BaseAuthLink>
                    </div>
                )}

                <AuthToggleLink pageType="signIn" />
            </div>
        );
    }
}

export default SignIn;
