import { useEffect, useRef, useState } from 'react';

import { useMounted } from '../../../hooks';
import { SimpleCallback } from '@/types/types';
import { ConnectExternalStorageProps } from '../interfaces';
import { captureErrorForSentry } from '../../../utils';

type CodeClientConfig = google.accounts.oauth2.CodeClientConfig;

type CodeClient = google.accounts.oauth2.CodeClient;

/* eslint-disable camelcase */
interface CodeResponse {
    scope: string[];
    state: string;
    code?: string;
    error?: string;
    error_description?: string;
    error_uri?: string;
}
/* eslint-enable camelcase */

const SCOPES_LIST = [
    'openid',
    'profile',
    'email',
    'https://www.googleapis.com/auth/drive.install',
    'https://www.googleapis.com/auth/drive.file',
];

const BASE_OAUTH_PARAMS: Pick<CodeClientConfig, 'ux_mode' | 'scope' | 'select_account'> = {
    ux_mode: 'popup',
    scope: SCOPES_LIST.join(' '),
    select_account: true,
};

const EXTERNAL_SDK_SRC = 'https://accounts.google.com/gsi/client';

const loadScript = (
    id: string,
    jsSrc: string,
    onSuccess: SimpleCallback,
    onError: SimpleCallback,
): void => {
    const firstScript: HTMLScriptElement = document.getElementsByTagName('script')[0];
    const js: HTMLScriptElement = document.createElement('script');
    js.id = id;
    js.src = jsSrc;
    if (firstScript?.parentNode) {
        firstScript.parentNode.insertBefore(js, firstScript);
    } else {
        document.head.appendChild(js);
    }
    js.onerror = onError;
    js.onload = onSuccess;
};

type IncomeParams = Pick<ConnectExternalStorageProps, 'clientId' | 'responseFail' | 'responseSuccess'>;

export interface GoogleIdentityService {
    isExternalScriptLoaded: boolean;
    oauthClickHandler: SimpleCallback;
}

const useGoogleIdentityService = ({
    clientId,
    responseSuccess,
    responseFail,
}: IncomeParams): GoogleIdentityService => {
    const [isExternalScriptLoaded, setIsExternalScriptLoaded] = useState(false);

    const clientRef = useRef<CodeClient>(null);

    const isMountedRef = useMounted();

    const oauthClickHandler = (): void => {
        if (clientRef.current) {
            clientRef.current.requestCode();
        } else {
            responseFail(new Error('Google SKD is not loaded'));
        }
    };

    useEffect(() => {
        loadScript(
            'google-login',
            EXTERNAL_SDK_SRC,
            () => {
                const params: CodeClientConfig = {
                    ...BASE_OAUTH_PARAMS,
                    client_id: clientId,
                    callback: (response: unknown) => {
                        const { error, code } = response as CodeResponse;
                        if (error) {
                            responseFail(new Error(error));
                        } else {
                            responseSuccess({ code });
                        }
                    },
                };

                if (isMountedRef.current) {
                    clientRef.current = window.google.accounts.oauth2.initCodeClient(params);
                    setIsExternalScriptLoaded(true);
                }
            },
            () => {
                console.error('could not load google SDK');
                captureErrorForSentry(new Error('could not load google SDK'), 'useGoogleIdentityService.loadScript');
                if (isMountedRef.current) {
                    setIsExternalScriptLoaded(true);
                }
            },
        );
    }, []);

    return { isExternalScriptLoaded, oauthClickHandler };
};

export default useGoogleIdentityService;
