import type { Dict } from '../components/utils';
import { dictToQuery } from '../components/utils';
import type { AllPermissionActions, OTPAuthType, StorageProvider } from '@/types/types';
import type {
    APIName,
    BackendTypes,
    Endpoint,
} from './interfaces';
import { BackendServices } from './interfaces';

export const BASEURL: Record<BackendTypes, () => APIName> = {
    backend: () => 'specterx',
    localhost: () => 'localhost',
    backendProxy: () => 'specterxProxy',
};

const ACCESS_ENDPOINTS = {
    // files
    createFolder: (): Endpoint => [BackendServices.access, 'folders'], // POST
    getFileDetailsPublic: (fileId): string => `${BackendServices.access}public/files/${fileId}`, // GET
    getFiles: (query: string): Endpoint => [BackendServices.access, `files${query}`], // GET
    getMultipleFiles: (filesIds: string[]): Endpoint => (
        [BackendServices.access, `files/multiple?files_ids=${filesIds}`]
    ), // GET
    getFilePath: (fileId: string): Endpoint => (
        [BackendServices.access, `files/${fileId}/breadcrumbs?full_metadata=true`]
    ), // GET
    getFileDetails: (fileId): Endpoint => [BackendServices.access, `files/${fileId}?use_command_api=true`], // GET
    getAllowedAppsPublic: (fileId): string => `${BackendServices.access}public/files/${fileId}/allowed-apps`, // GET
    openFile: (fileId: string): Endpoint => [BackendServices.access, `files/${fileId}/open`], // POST
    verifyAccess: (fileId: string): string => `${BackendServices.access}files/${fileId}/verify-access`, // POST
    deleteFile: (fileId: string): Endpoint => [BackendServices.access, `files/${fileId}`], // DELETE
    moveFiles: (folderId: string): Endpoint => [BackendServices.access, `folders/${folderId}/move`], // PUT
    newFile: (): Endpoint => [BackendServices.access, 'files'], // POST
    getFileMetadata: (fileId: string): Endpoint => [BackendServices.access, `files/${fileId}/metadata`], // GET
    // permissions
    getSharedFiles: (origin: string, filesIdsQueryString: string): Endpoint => (
        [BackendServices.access, `files?origin=${origin}${filesIdsQueryString}&allowUnmanaged=true`]
    ),
    shareFiles: (): Endpoint => [BackendServices.access, 'share'], // POST
    shareFile: (fileId: string, userId = ''): Endpoint => {
        const URLLastPart = userId === '' ? `${fileId}/permissions` : `${fileId}/permissions/${userId}`;
        return [BackendServices.access, `files/${URLLastPart}`];
    }, // GET, PUT, PATCH
    checkAbilityTo: (fileId: string, userId: string, action: AllPermissionActions): Endpoint => (
        [BackendServices.access, `files/${fileId}/permissions/${userId}?action=${action}`]
    ), // GET
    revoke: (fileId: string): Endpoint => [BackendServices.access, `${fileId}/revoke`], // POST
    getEmails: (): Endpoint => [BackendServices.access, 'emails'], // GET
    getSharingStats: (): Endpoint => [BackendServices.access, 'permissions/summary'], // GET
    getOwnersList: (): Endpoint => [BackendServices.access, 'files/shared/owners/info'], // GET
    clearUserCache: (userId: string): Endpoint => [BackendServices.access, `users/${userId}`], // DELETE
    changeOwner: (workspaceId: string): Endpoint => [BackendServices.access, `files/${workspaceId}/owner`], // PATCH
    // links
    getFileLink: (fileId: string, recpEmail: string, origin?: StorageProvider): Endpoint => {
        let urlSecondPart = `files/${fileId}/link?recp_email=${recpEmail}`;
        if (origin) {
            urlSecondPart = `${urlSecondPart}&origin=${origin}`;
        }
        return [BackendServices.access, urlSecondPart];
    }, // GET
    createMultipleFilesLink: (): Endpoint => [BackendServices.access, 'files/multiple_link'], // POST
    // batch download
    startBatchDownload: (): Endpoint => [BackendServices.access, 'files/batch-download'], // POST
    getBatchDownload: (batchId: string): Endpoint => [BackendServices.access, `files/batch-download/${batchId}`], // GET
    // policy
    filePolicy: (fileId: string): Endpoint => [BackendServices.access, `files/${fileId}/policy`], // PUT
    getPolicyConfirmed: (fileId): Endpoint => [BackendServices.access, `files/${fileId}/trypolicy`], // PUT
    workspaceNotify: (workspaceId): Endpoint => [BackendServices.access, `files/${workspaceId}/notify`], // POST
};

const ADMIN_ENDPOINTS = {
    getApiSettings: (): Endpoint => [BackendServices.admin, 'apikeys'], // GET
    getApiSettingsStorageKeys: (storage): Endpoint => (
        [BackendServices.admin, `apikeys/fields?service=${storage}`]
    ), // GET
    policies: (): Endpoint => [BackendServices.admin, 'policies?fields=settings'], // GET
    policy: (id?: string): Endpoint => [BackendServices.admin, `policies${id ? `/${id}` : ''}`], // GET
    checkIsOrgDomain: (domain: string): string => `${BackendServices.admin}check-org-domain?domain=${domain}`, // GET
    checkIPAccess: (): string => `${BackendServices.admin}check_ip`, // GET
};

const ORGANIZATIONS_ENDPOINTS = {
    getOrganizationsUsers: (): Endpoint => [BackendServices.organizations, 'users'], // GET
    assignUserToTheOrganization:
        (id?: string): Endpoint => [BackendServices.organizations, `users${id ? `/${id}` : ''}`], // POST
    inviteUserToTheOrganization: (): Endpoint => [BackendServices.organizations, 'invite-users'], // POST
    changeOrganizationUserRole:
        (): Endpoint => [BackendServices.organizations, 'users'], // POST
    deleteOrganizationUser:
        (id?: string): Endpoint => [BackendServices.organizations, `users${id ? `/${id}` : ''}`], // DELETE
};

const AUDITS_ENDPOINTS = {
    getAudits: (size?: number, queryObject?: Dict): Endpoint => {
        const queryString = dictToQuery(queryObject);
        const URLSecondPart = queryString ? `actions?pageSize=${size}&${queryString}` : `actions?pageSize=${size}`;
        return [BackendServices.audit, URLSecondPart];
    }, // GET
    generateEmailBecomeLicensedUser: (): Endpoint => [BackendServices.audit, 'notifications'], // POST
};

const AUTH_ENDPOINTS = {
    checkHasMfa: (emailList): string => `${BackendServices.auth}missing_phones?email=${emailList}`, // GET
    getPhoneCode: (): string => `${BackendServices.auth}send_code`,
    getMyPhoneNumber: (): string => `${BackendServices.auth}phone`, // GET
    setPhoneNumber: (email: string): Endpoint => [BackendServices.auth, `${email}/phone`], // POST
    getPhoneByEmail: (email: string): Endpoint => [BackendServices.auth, `${email}/phone`], // GET
    isGoogleRegister: (): string => `${BackendServices.auth}google`,
    isBoxRegister: (): string => `${BackendServices.auth}box/me`, // GET
    linkBoxAccount: (): string => `${BackendServices.auth}box`, // POST
    generateCode: (email: string, authType: OTPAuthType): string => (
        `${BackendServices.auth}code/${email}/${authType}`
    ), // POST
};

const ENTITIES_ENDPOINTS = {
    createUser: (): Endpoint => [BackendServices.entities, 'users'], // POST
    getUserGroups: (userId: string): Endpoint => [BackendServices.entities, `users/${userId}/groups`], // GET
    users: (): Endpoint => [BackendServices.entities, 'users'], // GET, PATCH
    roles: (): Endpoint => [BackendServices.entities, 'roles'], // GET, PATCH
    group: (groupId: string): string => `${BackendServices.entities}groups/${groupId}`, // GET, DELETE
    groups: (): string => `${BackendServices.entities}groups`, // GET, PUT
    groupUsers: (groupId: string): string => `${BackendServices.entities}groups/${groupId}/users`, // GET, PATCH
    deleteUser: (userId: string): Endpoint => [BackendServices.entities, `users/${userId}`], // DELETE
    getUserOrganizations: (): Endpoint => [BackendServices.entities, 'get_user_organizations'], // GET
    inviteUsers: (): Endpoint => [BackendServices.entities, 'users/invite'], // POST
};

const LINKS_ENDPOINTS = {
    getLinkDetails: (linkId: string): string => `${BackendServices.links}links/${linkId}`, // GET
    sendFeedback: (linkId: string): Endpoint => [BackendServices.links, `links/${linkId}/feedback`], // POST
};

const UPLOAD_ENDPOINTS = {
    getUploadDetailsMultipart: (): Endpoint => [BackendServices.upload, 'multipart'], // POST,
    getFileUploadURL: (uploadId, part): Endpoint => (
        [BackendServices.upload, `multipart/${uploadId}/${part}/url`]
    ), // POST
    completeMultipartFiles: (uploadId): Endpoint => [BackendServices.upload, `multipart/${uploadId}/complete`], // POST
    uploadFile: (): Endpoint => [BackendServices.upload, 'files'], // POST
    overwriteFile: (fileId: string): Endpoint => [BackendServices.upload, `files/${fileId}`], // PUT
    uploadAppInfo: (): string => `${BackendServices.upload}appinfo`, // GET
};

// TODO: create separate endpoint collections for each BE service
export const ENDPOINTS = {
    ...ACCESS_ENDPOINTS,
    ...ADMIN_ENDPOINTS,
    ...AUDITS_ENDPOINTS,
    ...AUTH_ENDPOINTS,
    ...ENTITIES_ENDPOINTS,
    ...LINKS_ENDPOINTS,
    ...UPLOAD_ENDPOINTS,
    ...ORGANIZATIONS_ENDPOINTS,
    appInfo: (): string => BackendServices.appinfo, // GET
};
