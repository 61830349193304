import { FC } from 'react';

import { observer } from 'mobx-react';

import { Card } from 'antd';

import { FileNameView } from './FileNameView';
import { UserNameView } from './UserNameView';

import { FileSummary, Recipient } from '@/types/types';

import './index.scss';

type SharedBlockType = 'sharedWith' | 'sharedFiles';

interface SharedBlockCardProps {
    sharedBlockType: SharedBlockType;
    uploadedFilesSummary?: FileSummary[];
    recipientsList?: Recipient[];
}

const SharedBlockCard: FC<SharedBlockCardProps> = ({
    sharedBlockType,
    uploadedFilesSummary,
    recipientsList,
}) => {
    const linkContent = sharedBlockType === 'sharedFiles'
        ? 'Link for all recipients'
        : 'Indvidual link';

    const titleContent = sharedBlockType === 'sharedFiles'
        ? `${uploadedFilesSummary.length} Shared files`
        : 'Shared with';

    return (
        <Card
            title={titleContent}
            extra={linkContent}
        >
            <div className="shared-block-content-container">
                {(sharedBlockType === 'sharedFiles' && uploadedFilesSummary) ? (
                    <div className="names-container">
                        {uploadedFilesSummary.map(({ fid, filename }) => (
                            <FileNameView
                                key={fid}
                                filename={filename}
                            />
                        ))}
                    </div>
                ) : (
                    <div className="names-container">
                        {recipientsList.map(({ id, email, role }) => (
                            <UserNameView
                                key={id}
                                username={email}
                                role={role}
                            />
                        ))}
                    </div>
                )}

            </div>
        </Card>
    );
};

export default observer(SharedBlockCard);
