import { I18Payload } from '@/content';
import { AuthErrorType } from '../../types/types';
import findEmailInString from './findEmailInString';

type MessageStoredErrorTypes = Exclude<AuthErrorType, 'serverIssue'>;

const ERROR_ATTRIBUTES: Record<MessageStoredErrorTypes, string> = {
    notInvited: 'user from this organization already signed in',
    accessDenied: 'error=access_denied',
    oauthSignUp: 'an account created',
    adminAccessDenied: 'as an administrator, you are not allowed to use otp to login.',
};

export const parseAuthErrorType = (message: string): MessageStoredErrorTypes => {
    const messageLower = message?.toLowerCase();
    return (Object.keys(ERROR_ATTRIBUTES) as MessageStoredErrorTypes[])
        .find((key) => messageLower?.includes(ERROR_ATTRIBUTES[key]));
};

export const parseAdminEmail = (message: string): string => findEmailInString(message)[0];

type OptionsCreator = (message?: string) => I18Payload[1];

export const MAP_AUTH_ERROR_TYPE_TO_I18N_OPTIONS_CREATOR: Partial<Record<AuthErrorType, OptionsCreator>> = {
    notInvited: (message) => ({ admin: parseAdminEmail(message) }),
    accessDenied: (message) => ({ originalMessage: message }),
};

export const getAuthI18nPayload = (errorType: AuthErrorType, message: string): I18Payload => ([
    `auth.errors.${errorType}`,
    MAP_AUTH_ERROR_TYPE_TO_I18N_OPTIONS_CREATOR[errorType]?.(message),
]);

export const authErrorMessageToI18nPayload = (
    message: string,
    fallback: () => I18Payload,
): I18Payload => {
    const errorType = parseAuthErrorType(message);
    if (errorType) {
        return getAuthI18nPayload(errorType, message);
    }
    return fallback();
};
